var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.tasksLoaded ? _c('v-col', {
    staticStyle: {
      "margin": "0",
      "padding": "0"
    }
  }, [_vm.title ? _c('h6', {
    staticClass: "sub-heading",
    staticStyle: {
      "padding-left": "0px",
      "padding-bottom": "0px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Tasks")) + " "), _c('button', {
    staticClass: "add-tasks",
    staticStyle: {
      "margin-left": "16px"
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.isOpen = !_vm.isOpen;
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/plus.svg"),
      "alt": ""
    }
  }), _c('span', {
    staticClass: "add-task-inside"
  }, [_vm._v(_vm._s(_vm.$t("AddTask.Title")))])])]) : _vm._e(), _c('div', {
    staticClass: "customer-row"
  }, [_vm._l(_vm.tasks, function (task) {
    return _c('v-row', {
      key: task._id,
      staticClass: "task-rows",
      staticStyle: {
        "margin": "0 !important",
        "padding": "0 !important"
      },
      attrs: {
        "justify": "space-between"
      }
    }, [task.statusCode != 0 ? _c('div', {
      staticClass: "task-row",
      staticStyle: {
        "display": "flex",
        "min-width": "100%",
        "justify-content": "space-between",
        "align-items": "center"
      }
    }, [_c('div', {
      staticClass: "logo-and-text",
      staticStyle: {
        "display": "flex",
        "gap": "8px",
        "width": "60%",
        "align-items": "center"
      }
    }, [_c('label', {
      staticClass: "container"
    }, [_c('input', {
      staticClass: "checkbox",
      attrs: {
        "type": "checkbox",
        "name": "peeter"
      },
      domProps: {
        "checked": task.isCompleted
      },
      on: {
        "change": function ($event) {
          return _vm.setCompletedStatus($event, task);
        }
      }
    }), _c('span', {
      staticClass: "checkmark"
    })]), task.isDisabled === true ? _c('p', {
      class: {
        'task-text': task.isCompleted === false,
        'task-text gray-text': task.isCompleted === true
      },
      staticStyle: {
        "margin-bottom": "0 !important"
      },
      on: {
        "click": function ($event) {
          return _vm.openField(task);
        }
      }
    }, [_vm._v(" " + _vm._s(task.content) + " ")]) : _vm._e(), task.isDisabled === false ? _c('v-textarea', {
      staticClass: "task-text text-area",
      staticStyle: {
        "margin-bottom": "0 !important"
      },
      attrs: {
        "auto-grow": "",
        "autofocus": "",
        "outlined": "",
        "row-height": "10",
        "rows": "1",
        "hide-details": ""
      },
      on: {
        "focusout": function ($event) {
          return _vm.closeTask(task);
        }
      },
      scopedSlots: _vm._u([{
        key: "end",
        fn: function () {
          return [_c('v-btn', {
            attrs: {
              "outlined": ""
            }
          })];
        },
        proxy: true
      }], null, true),
      model: {
        value: task.content,
        callback: function ($$v) {
          _vm.$set(task, "content", $$v);
        },
        expression: "task.content"
      }
    }) : _vm._e()], 1), _c('div', {
      staticClass: "info-text-wrapper"
    }, [task.assignedTo ? _c('p', {
      class: {
        'createdAt black-text': task.isCompleted === false,
        createdAt: task.isCompleted === true
      }
    }, [_vm._v(" " + _vm._s(task.assignedTo.name) + " ")]) : _vm._e(), task.dates.endDate ? _c('p', {
      class: {
        'createdAt black-text': task.isCompleted === false,
        createdAt: task.isCompleted === true
      }
    }, [_vm._v(" " + _vm._s(_vm.formatDate(task.dates.endDate)) + " ")]) : _vm._e()]), _c('div', {
      staticClass: "action-buttons-wrapper"
    }, [_c('v-menu', {
      ref: "menu",
      refInFor: true,
      staticClass: "no-active",
      attrs: {
        "close-on-content-click": true,
        "transition": "scale-transition",
        "offset-y": "",
        "min-width": "auto",
        "max-height": "250",
        "nudge-left": "70"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var onMenu = _ref.on;
          return [_c('v-tooltip', {
            attrs: {
              "bottom": ""
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function (_ref2) {
                var onTooltip = _ref2.on;
                return [_c('div', _vm._g({
                  staticClass: "action-button"
                }, Object.assign({}, onMenu, onTooltip)), [_c('img', {
                  attrs: {
                    "src": require("../../assets/images/user.svg"),
                    "alt": ""
                  }
                })])];
              }
            }], null, true)
          }, [_c('span', [_vm._v(_vm._s(_vm.$t("ListView.Header.assignee")))])])];
        }
      }], null, true)
    }, _vm._l(_vm.users, function (userItem) {
      return _c('v-row', {
        key: userItem._id,
        staticClass: "menu-item-person",
        on: {
          "click": function ($event) {
            return _vm.addAssignee(task, userItem);
          }
        }
      }, [_c('p', {
        staticClass: "userItem-name"
      }, [_vm._v(_vm._s(userItem.name))])]);
    }), 1), _c('date-pick', {
      on: {
        "input": function ($event) {
          return _vm.addDeadlineDate($event, task);
        }
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref3) {
          var toggle = _ref3.toggle;
          return [_c('div', {
            staticClass: "action-button",
            on: {
              "click": toggle
            }
          }, [_c('img', {
            attrs: {
              "src": require("../../assets/images/calendar.svg"),
              "alt": ""
            }
          })])];
        }
      }], null, true)
    })], 1)]) : _vm._e()]);
  }), _vm.isOpen || _vm.isAccordionOpen ? _c('v-row', {
    staticStyle: {
      "margin": "0",
      "padding": "0"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "8px",
      "width": "100%",
      "align-content": "center"
    }
  }, [_c('v-textarea', {
    staticClass: "task-text text-area",
    attrs: {
      "autofocus": "",
      "auto-grow": "",
      "outlined": "",
      "hide-details": "",
      "row-height": "10",
      "placeholder": _vm.$t('AddTask.Title') + '...',
      "textarea": "",
      "rows": "1"
    },
    on: {
      "focusout": function ($event) {
        return _vm.closeNewField();
      }
    },
    model: {
      value: _vm.newTask.content,
      callback: function ($$v) {
        _vm.$set(_vm.newTask, "content", $$v);
      },
      expression: "newTask.content"
    }
  }, [_vm._v(_vm._s(_vm.newTask))])], 1)]) : _vm._e()], 2)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }