<template>
  <div class="deal-list-content">
    <div
        style="width: 100%;"
        v-for="(value, key) in pipelinesWithDeals"
        :key="key"
    >
      <div class="deal-pipeline"
           v-if="(activeProjectsTab === 'projects' && key !== 'archived') ||
           (activeProjectsTab === 'archives' && key === 'archived')"
      >
        <v-row
            v-if="activeProjectsTab !== 'archives'"
            class="deal-header"
        >
          <div @click="setProjectListViewState(key)" style="display: flex; width: 100%; align-items: center; gap: 8px;">
            <span> {{ $t(`ProjectPipeline.${key}`) }} [{{ value.count }}] </span>
            <img
                src="../../assets/images/chevron.svg"
                alt=""
                :style="
            projectListViewStates[key]
              ? 'transform: rotate(180deg);'
              : 'transform: rotate(90deg);'
          "
            />
          </div>
          <BaseButton
              v-if="key === 'Done' && value.count > 0"
              @onConfirm="archiveDeals(value)"
              :display-key="'name'"
              :display-value="'value'"
              :items="DoneDonePipelineDropdown"
              :size="'icon'"
              :icon-left="'archive_button24_24.svg'"
              style="margin-right: 12px; max-height: 24px;"
          />
        </v-row>
        <draggable
            v-show="projectListViewStates[key]"
            :key="taskUpdater"
            :list="value.deals"
            :animation="200"
            ghost-class="ghost-card"
            chosen-class="chosen-card"
            group="tasks"
            :move="(val) => $emit('updatePipeline', val)"
            :id="'drag-' + key"
            class="scrollable"
        >
          <ListCard
              v-for="deal in value.deals"
              :key="deal._id"
              :deal="deal"
              :id="deal.pipelineStep"
              @cardClick="(dealId, event) => $emit('cardClick', dealId, event)"
              class="cursor-move"
              style="margin-top: 12px;"
          />
          <div style="width: 100%; height: 120px; display: flex; justify-content: center; align-items: center" v-if="value.loading">
            <div class="loader"></div>
          </div>
          <div style="width: 100%; height: 64px; display: flex; align-items: center; justify-content: center" v-else-if="!value.allLoaded && !value.loading">
            <div @click="getMoreProjects(key)" class="link-small">{{$t('LoadMoreProjects')}}</div>
          </div>
        </draggable>
      </div>

    </div>
  </div>
</template>
<script>
import ListCard from "@/components/Deals/ListCard";
import draggable from "vuedraggable";
import BaseButton from "@/components/common/BaseButton";
import {mapActions, mapGetters} from "vuex";
import axios from "axios";

export default {
  name: "ListContent",
  components: {
    ListCard,
    draggable,
    BaseButton,
  },
  props: ["pipelinesWithDeals", "taskUpdater"],
  computed: {
    ...mapGetters(["projectListViewStates", "activeProjectsTab", "provisionalFilters"]),
    DoneDonePipelineDropdown() {
      const count = this.pipelinesWithDeals['Done'].count;
      return [
        {
          name: this.$t("ArchiveCompleted"),
          value: "archive_completed",
          statusConfirmationModal: true,
          statusConfirmationModalText: `${this.$t("ArchiveVerb")} ${count} ${count > 1 || count === 0 ? this.$t("DealPlural") : this.$t('Deal').toLowerCase()}`,
        },
      ]
    },
  },
  methods: {
    ...mapActions(["setProjectListViewState"]),
    getMoreProjects(pipeline) {
      if (this.pipelinesWithDeals[pipeline].loading) return;
      if (!this.pipelinesWithDeals[pipeline].allLoaded) {
        this.pipelinesWithDeals[pipeline].loading = true;
        this.$emit('getProjectsForPipeline', pipeline)
      }
    },
    async archiveDeals() {
      let pipelineStep = "Done"
      let response = await axios.post(`/api/project/archive/all/${pipelineStep}`, { filters: this.provisionalFilters["projects"] });
      if (response.data === 1) {
        this.toastSuccess(
          this.$t("Projects") + " " + this.$t("Archived").toLowerCase()
        );
      } else {
        console.log("Failed to archive project(s)");
      }

      this.$nextTick(() => {
        this.$emit("reloadKanban", "Done");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/DealCard";

.deal-list-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 48px 72px 112px;
  max-width: min-content;
}

.deal-pipeline {
  min-width: 100%;
}

.deal-header {
  font-family: Geomanist;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 16px;
  cursor: pointer;
  display: flex;
  gap: 8px;
  width: 100%;
  flex-wrap: nowrap;
}

.scrollable {
  margin-bottom: 32px;
}
</style>
