var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vue-draggable-resizable', {
    staticClass: "widget-overlay",
    staticStyle: {
      "position": "fixed"
    },
    attrs: {
      "z": 3,
      "resizable": false,
      "drag-handle": ".drag-area",
      "id": "widget-overlay"
    },
    on: {
      "dragstop": _vm.onDragStop
    }
  }, [_c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closePanel,
      expression: "closePanel"
    }],
    staticClass: "content",
    attrs: {
      "id": "widget"
    }
  }, [_c('div', {
    staticClass: "drag-area"
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/menu.svg"),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "filter-panel-content"
  }, [_c('div', [_vm.filter.name == 'allValues' ? _c('input', {
    staticClass: "filter-title",
    attrs: {
      "placeholder": _vm.$t(_vm.filter.filterTitle),
      "disabled": true
    }
  }) : _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filter.filterTitle,
      expression: "filter.filterTitle"
    }],
    staticClass: "filter-title",
    attrs: {
      "placeholder": _vm.$t('AddTitle'),
      "disabled": _vm.filter.name === 'allValues'
    },
    domProps: {
      "value": _vm.filter.filterTitle
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.filter, "filterTitle", $event.target.value);
      }
    }
  })]), _vm.showErrors && !_vm.filter.filterTitle ? _c('div', {
    staticClass: "error-text"
  }, [_vm._v(" " + _vm._s(_vm.$t("FormErrors.FieldRequired")) + " ")]) : _vm._e(), _vm.showErrors && _vm.filter.filterTitle ? _c('div', {
    staticClass: "error-text"
  }, [_vm._v(" " + _vm._s(_vm.$t("FormErrors.FilterNameExists")) + " ")]) : _vm._e(), _c('div', {
    staticClass: "additional-parameters"
  }, [_c('div', {
    staticClass: "default-view",
    class: {
      'default-view-disabled': _vm.isToggleDisabled
    },
    on: {
      "click": _vm.clickToggle
    }
  }, [_c('img', {
    staticClass: "img24",
    class: {
      'toggle-disabled': _vm.isToggleDisabled
    },
    attrs: {
      "src": _vm.filter.isDefault ? require('../../assets/images/toggle-active-24x24.svg') : require('../../assets/images/toggle-inactive.svg'),
      "alt": ""
    }
  }), _c('div', [_vm._v(" " + _vm._s(_vm.$t("SelectedByDefaultInTheView")) + " ")])]), _vm.editFilter.name !== 'allValues' && _vm.editFilter._id !== '1' ? _c('div', {
    staticClass: "default-view",
    on: {
      "click": _vm.toggleOverwrite
    }
  }, [_c('img', {
    staticClass: "img24",
    attrs: {
      "src": _vm.isOverwrite ? require('../../assets/images/toggle-active-24x24.svg') : require('../../assets/images/toggle-inactive.svg'),
      "alt": ""
    }
  }), _c('div', [_vm._v(" " + _vm._s(_vm.$t("Overwrite")) + " ")])]) : _vm._e()])]), _c('div', {
    staticClass: "filter-panel-footer"
  }, [_c('span', {
    staticStyle: {
      "display": "flex",
      "gap": "8px"
    }
  }, [_c('button', {
    staticClass: "transparent-button",
    on: {
      "click": _vm.closePanel
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]), _c('button', {
    staticClass: "transparent-button",
    staticStyle: {
      "background": "black !important",
      "color": "white"
    },
    attrs: {
      "name": "generate-doc-button"
    },
    on: {
      "click": _vm.saveFilter
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")])])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }