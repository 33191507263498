var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeDropdown,
      expression: "closeDropdown"
    }],
    class: {
      dropdown: !_vm.disabled,
      'dropdown dropdown-disabled': _vm.disabled
    }
  }, [_c('div', {
    staticClass: "filter-dropdown-button",
    on: {
      "click": _vm.openDropdown
    }
  }, [_c('img', {
    staticClass: "img16",
    attrs: {
      "src": require("../../assets/images/bookmark.svg"),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "button-small"
  }, [_vm._v(" " + _vm._s(_vm.selectedItem.name === "allValues" ? _vm.$t(_vm.selectedItem.filterTitle) : _vm.selectedItem.filterTitle) + " ")]), _c('img', {
    staticClass: "img16",
    attrs: {
      "src": require("../../assets/images/chevron-disabled.svg"),
      "alt": ""
    }
  })]), _vm.dropdownOpen ? _c('div', {
    staticClass: "dropdown-single scrollable"
  }, [_vm._l(_vm.dropdownItems, function (item, index) {
    return _c('div', {
      key: 'dropdown-item-' + index,
      staticClass: "dropdown-item",
      class: {
        'selected-dropdown-item': item._id === _vm.selectedItem._id
      },
      on: {
        "click": function ($event) {
          return _vm.selectItem(item);
        }
      }
    }, [item._id === _vm.selectedItem._id ? _c('img', {
      staticClass: "img16",
      attrs: {
        "src": require("../../assets/images/checkmark.svg"),
        "alt": ""
      }
    }) : _vm._e(), _c('div', {
      staticClass: "content"
    }, [_vm._v(" " + _vm._s(item.name === "allValues" ? _vm.$t(item.filterTitle) : item.filterTitle) + " ")]), item.isDefault ? _c('div', [_vm._v("•")]) : _vm._e(), item.isDefault ? _c('div', {
      staticClass: "content-small-medium"
    }, [_vm._v(" " + _vm._s(_vm.$t("Default").toLowerCase()) + " ")]) : _vm._e()]);
  }), _c('div', {
    staticClass: "dropdown-divider"
  }), _vm._l(_vm.dropdownDefaultItems, function (defaultItem, index) {
    return _c('div', {
      key: 'dropdown-default-item-' + index,
      staticClass: "dropdown-item",
      on: {
        "click": function ($event) {
          return _vm.selectDefaultItem(defaultItem);
        }
      }
    }, [_c('img', {
      staticClass: "img16",
      attrs: {
        "src": require(`../../assets/images/${defaultItem.icon}`),
        "alt": ""
      }
    }), _c('div', {
      staticClass: "content"
    }, [_vm._v(" " + _vm._s(_vm.$t(defaultItem.name)) + " ")])]);
  })], 2) : _vm._e(), _vm.filterPanelOpen ? _c('FilterPanel', {
    attrs: {
      "filter-name": _vm.filterName,
      "edit-filter": _vm.selectedItem,
      "edit": _vm.editFilter
    },
    on: {
      "closePanel": function ($event) {
        _vm.filterPanelOpen = false;
      }
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }