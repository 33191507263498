<template>
  <div
    v-click-outside="closeDropdown"
    :class="{
      dropdown: !disabled,
      'dropdown dropdown-disabled': disabled,
    }"
  >
    <div @click="openDropdown" class="filter-dropdown-button">
      <img src="../../assets/images/bookmark.svg" alt="" class="img16" />
      <div class="button-small">
        {{
          selectedItem.name === "allValues"
            ? $t(selectedItem.filterTitle)
            : selectedItem.filterTitle
        }}
      </div>

      <img
        src="../../assets/images/chevron-disabled.svg"
        alt=""
        class="img16"
      />
    </div>
    <div v-if="dropdownOpen" class="dropdown-single scrollable">
      <div
        v-for="(item, index) in dropdownItems"
        :key="'dropdown-item-' + index"
        class="dropdown-item"
        @click="selectItem(item)"
        :class="{
          'selected-dropdown-item': item._id === selectedItem._id,
        }"
      >
        <img
          v-if="item._id === selectedItem._id"
          src="../../assets/images/checkmark.svg"
          alt=""
          class="img16"
        />

        <div class="content">
          {{
            item.name === "allValues" ? $t(item.filterTitle) : item.filterTitle
          }}
        </div>

        <div v-if="item.isDefault">•</div>

        <div v-if="item.isDefault" class="content-small-medium">
          {{ $t("Default").toLowerCase() }}
        </div>
      </div>
      <div class="dropdown-divider" />
      <div
        v-for="(defaultItem, index) in dropdownDefaultItems"
        :key="'dropdown-default-item-' + index"
        class="dropdown-item"
        @click="selectDefaultItem(defaultItem)"
      >
        <img
          :src="require(`../../assets/images/${defaultItem.icon}`)"
          alt=""
          class="img16"
        />
        <div class="content">
          {{ $t(defaultItem.name) }}
        </div>
      </div>
    </div>

    <FilterPanel
      v-if="filterPanelOpen"
      :filter-name="filterName"
      :edit-filter="selectedItem"
      :edit="editFilter"
      @closePanel="filterPanelOpen = false"
    />
  </div>
</template>

<script>
import FilterPanel from "@/components/common/FilterPanel";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "FilterDropdown",
  components: {
    FilterPanel,
  },
  props: ["filterName", "fromDashboard"],
  data: () => ({
    selectedItem: null,
    disabled: false,
    dropdownOpen: false,
    filterPanelOpen: false,
    editFilter: false,
    dropdownItems: [],
    dropdownDefaultItems: [
      { name: "SaveView", value: "saveView", icon: "plus.svg" },
      { name: "DeleteView", value: "deleteView", icon: "TrashBin.svg" },
    ],
  }),
  created() {
    this.dropdownItems = this.filters[this.filterName];
    console.log('filterName: ', this.filterName)
    console.log('dropdownItems: ', this.dropdownItems)
    console.log('filters: ', this.filters)
    const stateValue = this.viewState[this.filterName];

    for (let i = 0; i < this.dropdownItems.length; i++) {
      const dropdownItem = this.dropdownItems[i];
      if (
        (!stateValue && dropdownItem.isDefault) ||
        (stateValue && dropdownItem.isSelected)
      ) {
        dropdownItem.isSelected = true;
        this.selectedItem = dropdownItem;
        this.setViewState({
          viewName: this.filterName,
          value: false,
        });
        this.setProvisionalFilter({
          filterName: this.filterName,
          conditions: dropdownItem.conditions,
        });
        break;
      }
    }

    if (!this.selectedItem) {
      this.dropdownItems[0].isSelected = true;
      this.selectedItem = this.dropdownItems[0];
    }
    this.$emit("currentSelectedFilter", this.selectedItem);
  },
  mounted() {
    if (this.fromDashboard) {
      this.dropdownItems.forEach((dropdownItem) => {
        if (this.fromDashboard && dropdownItem._id === this.fromDashboard) {
          this.selectedItem = dropdownItem;
          dropdownItem.isSelected = true;
          this.setViewState({
            viewName: this.filterName,
            value: false,
          });
          this.setProvisionalFilter({
            filterName: this.filterName,
            conditions: dropdownItem.conditions,
          });
        } else {
          dropdownItem.isSelected = false;
        }
      });
      this.$emit("currentSelectedFilter", this.selectedItem);
    }
  },
  watch: {
    fromDashboard(val) {
      if (!val) {
        return;
      }
      this.dropdownItems.forEach((dropdownItem) => {
        if (this.fromDashboard && dropdownItem._id === this.fromDashboard) {
          this.selectedItem = dropdownItem;
          dropdownItem.isSelected = true;
          this.setViewState({
            viewName: this.filterName,
            value: false,
          });
        } else {
          dropdownItem.isSelected = false;
        }
      });
      this.$emit("currentSelectedFilter", this.selectedItem);
    },
    filtersUpdater() {
      this.dropdownItems = this.filters[this.filterName];
      this.dropdownItems.forEach((dropdownItem) => {
        if (dropdownItem.isSelected) {
          this.selectedItem = dropdownItem;
          return false;
        }
      });
    },
  },
  computed: {
    ...mapGetters(["filters", "filtersUpdater", "viewState"]),
    filterTitle() {
      if (this.selectedItem.name === "allValues") {
        console.log("HEREEE", this.selectedItem);
        return this.$t(this.selectedItem.filterTitle);
      } else {
        return this.selectedItem.filterTitle;
      }
    },
  },
  methods: {
    ...mapActions([
      "setFiltersByFilterName",
      "setProvisionalFilter",
      "setViewState",
      "removeFilterById",
    ]),
    initFilters() {
      this.dropdownItems = this.filters[this.filterName];
      this.dropdownItems.forEach((dropdownItem) => {
        if (dropdownItem.isDefault) {
          this.selectedItem = dropdownItem;
          return false;
        }
      });
    },
    deleteView() {
      if (this.selectedItem.name !== "allValues") {
        this.removeFilterById({
          filterName: this.filterName,
          filterId: this.selectedItem._id,
        });
      }
    },
    closeDropdown() {
      this.dropdownOpen = false;
    },
    selectItem(item) {
      console.log('item: ', item)
      this.dropdownItems.forEach((dropdown) => (dropdown.isSelected = false));
      item.isSelected = true;
      this.selectedItem = item;
      this.setFiltersByFilterName({
        filterName: this.filterName,
        filters: this.dropdownItems,
      });
      this.setProvisionalFilter({
        filterName: this.filterName,
        conditions: item.conditions,
      });
      this.$emit("currentSelectedFilter", item);
      this.closeDropdown();
    },
    selectDefaultItem(item) {
      if (item.value === "deleteView") {
        this.deleteView();
        return;
      }
      this.editFilter = this.selectedItem.name !== "allValues";
      this.closeDropdown();
      this.filterPanelOpen = true;
    },
    openDropdown() {
      if (this.disabled) return;
      this.dropdownOpen = !this.dropdownOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.selected-dropdown-item {
  div {
    font-weight: 600;
  }
}

.widget-overlay {
  z-index: 1;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#widget {
  font-family: Inter, "sans-serif";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  position: fixed;

  padding: 24px;
  width: 416px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04), 0 1px 2px rgba(0, 0, 0, 0.03),
    0 0 1px rgba(0, 0, 0, 0.04);
}

.filter-dropdown-button {
  padding: 8px 12px;
  display: flex;
  gap: 8px;
  cursor: pointer;
  border: 1px solid $grey500;
  border-radius: 8px;
}

.dropdown-item {
  cursor: pointer;
  display: flex;
  gap: 10px;
  padding: 12px 16px;
  align-items: center;
}

.dropdown-divider {
  height: 1px;
  background: $grey500;
}

.dropdown-single {
  position: absolute;
  flex-direction: column;
  background-color: white;
  overflow: auto;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 120;
  border-radius: 8px;
  border: 1px solid #e6e8ec;
}

.dropdown-disabled {
  opacity: 0.5;
}
</style>
