<template>
  <div v-if="deal" class="overlay">
    <div id="panel-wrapper-deal" class="panel-wrapper">
      <div v-if="!deal" class="overlay">
        <div class="overlay__inner">
          <div class="overlay__content"><span class="spinner"></span></div>
        </div>
      </div>
      <div v-show="!editModalOpen">
        <!--        <v-row class="top-row">
          <button @click="closeDeal()" class="close-button">
            <img src="../../assets/images/cross.svg" alt="" />
          </button>
        </v-row>-->
        <v-col class="customer-row">
          <v-row style="justify-content: space-between">
            <h4 v-if="deal.name" style="align-self: center">{{ deal.name }}</h4>

            <div style="display: flex; gap: 8px; justify-content: flex-end">
              <!--  <p
                v-if="editMode"
                @click="deleteDeal()"
                class="edit-button delete"
              >
                Delete
              </p> -->
              <div @click="openSidepanelAdd(deal)" class="edit-button">
                <img src="../../assets/images/edit.svg" alt="" />
                <p class="edit-button-text">Edit</p>
              </div>

              <!--  <p
                v-if="editMode"
                @click="editMode = !editMode"
                class="edit-button cancel"
              >
                Cancel
              </p>
              <p
                v-if="editMode"
                @click="
                  saveDeal(deal);
                  editMode = !editMode;
                "
                class="edit-button save"
              >
                Save
              </p> -->
            </div>
          </v-row>
          <v-row style="gap: 18px; align-items: center; height: 30px">
            <p
              v-if="deal.amount != '' && deal.amount != null"
              class="edit-button-text"
            >
              {{ deal.amount }} €
            </p>
            <div class="vertical-line"></div>
            <div style="display: flex; gap: 8px">
              <img src="../../assets/images/user.svg" alt="" />
              <p class="edit-button-text">
                {{ deal.contactPerson.customerName }}
              </p>
            </div>
            <div class="vertical-line"></div>
            <div style="display: flex; gap: 8px; align-items: center">
              <img src="../../assets/images/building16x16.svg" alt="" />
              <p class="edit-button-text">
                {{ deal.objectName }}
              </p>
            </div>
            <div class="vertical-line"></div>
            <div style="display: flex; gap: 8px; align-items: center">
              <p class="edit-button-text">
                {{ deal.dealType }}
              </p>
            </div>
          </v-row>
        </v-col>
        <div class="main-wrapper">
          <div class="sub-block">
            <v-col class="sub-heading"
              ><h6>
                {{ $t("Description") }}
              </h6>
            </v-col>
            <div class="description">
              <textarea
                outlined
                rows="5"
                :placeholder="$t('Description')"
                :disabled="!editMode"
                class="description-item"
                v-model="deal.description"
              >
              </textarea>
            </div>
          </div>
          <div class="sub-block">
            <h6 class="sub-heading">
              {{ $t("Notes") }}
            </h6>
            <div class="notes">
              <Notes
                :notes="notes"
                :newNote="newNote"
                @newNote="addNewNote($event)"
                @saveNotesChild="saveNotes($event)"
              />
            </div>
          </div>
          <div class="sub-block">
            <h6 class="sub-heading">
              {{ $t("Tasks") }}
            </h6>
            <div class="activities">
              <Tasks
                :tasks="tasks"
                :newTask="newTask"
                @newTask="addNewTask($event)"
                @saveTasksChild="saveTasks($event)"
              />
            </div>
          </div>
          <div class="sub-block">
            <h6 class="sub-heading">
              {{ $t("ContactPerson2") }}
            </h6>
            <v-autocomplete
              v-if="editMode"
              v-model="deal.contactPerson"
              outlined
              prepend-inner-icon="mdi-magnify"
              dense
              :items="customers"
              autocomplete="nope"
              item-text="customerName"
              return-object
              :label="$t('ContactPerson')"
              class="no-active required"
              ><template v-slot:prepend-inner>
                <img
                  width="20"
                  height="20"
                  src="../../assets/images/user.svg"
                />
              </template>
            </v-autocomplete>
            <p
              v-if="!editMode"
              class="value description contactperson"
              style="cursor: pointer"
              @click="
                $router.push({
                  name: 'KliendiregisterDetail',
                  params: { id: deal.contactPerson.customerId },
                })
              "
            >
              {{ deal.contactPerson.customerName }}
            </p>
          </div>
          <div class="sub-block">
            <h6 class="sub-heading">
              {{ $t("RelatedListings") }}
            </h6>
            <div class="related-content">
              <v-row style="align-items: end" class="object-row object">
                <img
                  class="filtered"
                  src="../../assets/images/building.svg"
                  alt=""
                />
                <p
                  v-if="!deal.objectName"
                  v-on:click.stop="
                    $router.push({
                      name: 'ListingsAdd',
                      params: { id: deal._id },
                    })
                  "
                  class="object-link"
                >
                  Lisa kuulutus
                </p>
                <p
                  v-if="deal.objectName"
                  @click="
                    $router.push({
                      path: `/listings/listing/${deal.object._id}`,
                    })
                  "
                  class="object-link"
                >
                  {{ deal.objectName }}
                </p>
              </v-row>
            </div>
          </div>
          <FileUpload
            @success="getDeal"
            :files.sync="deal.files"
            :targetId="deal._id"
            :target-object="'project'"
          />
        </div>
      </div>
    </div>
    <SidepanelAdd
      v-if="editModalOpen"
      :dealData="deal"
      :isEdit="true"
      @closed="closeSidepanelAdd()"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import Notes from "../common/Notes.vue";
import Tasks from "../common/Tasks.vue";
import SidepanelAdd from "../../components/Deals/SidepanelAdd.vue";
import FileUpload from "../common/FileUpload.vue";
import nanoid from "nanoid";

export default {
  name: "SidepanelDetail",
  components: {
    Notes,
    Tasks,
    FileUpload,
    SidepanelAdd,
  },
  data: () => ({
    notes: null,
    editModalOpen: false,
    newNote: {
      content: "",
      linkedTo: null,
      parentName: null,
      createdAt: null,
    },
    tasks: null,
    newTask: {
      id: nanoid(20),
      content: "",
      linkedTo: null,
      createdAt: null,
      isCompleted: false,
      parentName: null,
    },
    deal: null,
    dealTypes: ["Müügitehing", "Üüritehing"],
    currencies: ["EUR", "USD"],
    menu: false,
    editMode: false,
    form: {
      name: null,
      dealType: null,
      description: null,
      amount: null,
      currency: "EUR",
      probability: null,
      estimatedCloseDate: null,
      pipelineStep: null,
      contactPerson: null,
      organization: null,
      object: {},
      objectName: null,
    },
  }),
  created() {},
  mounted() {
    this.getDeal();
    window.onclick = (e) => {
      if (e.target.classList[0] === "overlay") {
        this.changeSidepanelStateDeal(0);
        e.target.style.display = "none";
        this.$router.back();
      }
    };
    document.onkeydown = (evt) => {
      evt = evt || window.event;
      if (evt.key === "Escape") {
        this.closePanel();
        document.onkeydown = null;
      }
    };
  },
  computed: {
    ...mapGetters(["customers"]),
  },
  watch: {
    "$route.params.id": function () {
      this.getDeal();
    },
  },
  methods: {
    ...mapActions(["changeSidepanelStateDeal", "addNotesDeal"]),
    openSidepanelAdd() {
      this.editModalOpen = true;
      //document.getElementById("panel-wrapper-deal").style.overflowY = "hidden";
      //document.getElementsByClassName("panel-wrapper-deal").classList.add("modal-open");
      document.getElementById("panel-wrapper-deal").style.display = "none";
    },
    closeSidepanelAdd() {
      this.$emit("closed");
      this.editModalOpen = false;
      //document.getElementById("panel-wrapper-deal").addClass("modal-close");
      //document.getElementsByClassName("panel-wrapper-deal").classList.remove("modal-open");
      document.getElementById("panel-wrapper-deal").style.display = "initial";
      this.getDeal(this.$route.params.id);
      //window.location.reload();
    },
    async closePanel() {
      this.changeSidepanelStateDeal(0);
      this.setClientSidepanel(0);
      await this.$router.back();
    },
    openField(note) {
      this.$set(note, "isDisabled", false);
      this.$forceUpdate();
    },
    closeFields() {
      this.notes.forEach((e) => {
        e.isDisabled = true;
      });
      this.$forceUpdate();
    },
    addNewNote(newNote) {
      if (newNote.content != "") {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, "0");
        let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = today.getFullYear();

        today = dd + "/" + mm + "/" + yyyy;
        newNote.createdAt = today;
        this.notes.push(newNote);
      } else {
        return;
      }
    },

    async getNotes() {
      let dealId = this.$route.params.id;
      const resp = await axios.get(`/api/project/notes/${dealId}`);

      this.notes = resp.data.notes[0].notes;
      this.notes.sort((b, a) =>
        a.createdAt > b.createdAt ? 1 : b.createdAt > a.createdAt ? -1 : 0
      );
      this.notes.forEach((e) => {
        e.isDisabled = true;
      });
    },
    async saveNotes(notes) {
      this.notes = notes;
      try {
        this.notes.forEach((el) => {
          if (el.content == "") {
            el.statusCode = 0;
          }
        });
        this.deal.notes = this.notes;

        await axios.patch(
          `/api/project/notes/${this.$route.params.id}`,
          this.notes
        );

        this.newNote.content = "";
        await this.getNotes();
      } catch (err) {
        console.log(err);
      }
    },
    addNewTask(newTask) {
      if (newTask.content != "") {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, "0");
        let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = today.getFullYear();

        today = dd + "/" + mm + "/" + yyyy;
        newTask.createdAt = today;
        this.tasks.push(newTask);
      } else {
        return;
      }
    },
    async getTasks() {
      let dealId = this.$route.params.id;
      const resp = await axios.get(`/api/project/tasks/${dealId}`);

      this.tasks = resp.data.tasks[0].tasks;
      this.tasks.sort((b, a) =>
        b.isCompleted > a.isCompleted
          ? 1
          : a.isCompleted > b.isCompleted
          ? -1
          : 0
      );
      this.tasks.forEach((e) => {
        e.isDisabled = true;
      });
    },
    async saveTasks(tasks) {
      this.tasks = tasks;
      try {
        this.tasks.forEach((el) => {
          if (el.content == "") {
            el.statusCode = 0;
          }
        });
        this.deal.tasks = this.tasks;
        await axios.patch(
          `/api/project/tasks/${this.$route.params.id}`,
          this.tasks
        );
        this.newTask.content = "";
        await this.getTasks();
      } catch (err) {
        console.log(err);
      }
    },
    async getDeal() {
      let chosenObject = this.$route.params.id;
      const response = await axios.get(`/api/project/${chosenObject}`);
      this.deal = response.data[0];
      await this.getNotes();
      await this.getTasks();
      this.newNote.linkedTo = this.deal._id;
      this.newTask.linkedTo = this.deal._id;
      this.newNote.parentName = this.deal.name;
      this.newTask.parentName = this.deal.name;
    },
    async saveDeal(deal) {
      deal.contactPersonName = deal.contactPerson.customerName;
      await axios.patch("/api/project", deal);
      await this.changeSidepanelStateDeals(0);
    },
    async deleteDeal() {
      let listings = this.deal.objects[0].listings;
      const response = await axios.post(
        `/api/project/delete/${this.deal._id}`,
        listings
      );
      console.log(response);
      await this.changeSidepanelStateDeal(0);
      await this.$router.push("/projects");
      await this.$router.go();
    },
    closeDeal() {
      this.changeSidepanelStateDeal(0);
      window.history.replaceState({}, document.title, "/projects");
    },
  },
};
</script>
<style lang="scss" scoped>
.note-row {
  flex-flow: row;
  margin-top: 16px !important;
  cursor: pointer;
  max-width: 100%;
}
.note-text {
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  width: 100%;
  padding-left: 4px;
  box-sizing: border-box;
}
.note-text:hover {
  cursor: pointer;
  /*
  box-shadow: inset 0px 0px 0px 1px rgb(230, 232, 236);
*/
  border-radius: 8px;
}
.createdAt {
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;
  margin-bottom: 0 !important;

  /* grey/grey900 */

  color: #939597;
}
.add-field {
  margin-bottom: 0 !important;
  width: 100% !important;
}
.notes {
  padding-left: 12px;
  padding-right: 28px;
  padding-bottom: 12px;
}
.related-content {
  padding-left: 24px;
  padding-right: 56px;
  padding-bottom: 24px;
}
.add-notes {
  height: 50px;
  flex-direction: row;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-family: Inter;
  border-radius: 8px;
  height: 40px;
  padding: 0;
  justify-content: space-between;
}
.add-note-inside {
  width: 73px;
  height: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
}
.text-area {
  height: fit-content !important;
}
.delete {
  background-color: #ff1e24;
  color: white;
}
.theme--light.v-input--is-disabled,
.theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: black !important;
}
.v-input__slot {
}
.v-text-field--outlined ::v-deep fieldset {
  border: 1px solid #e6e8ec;
}
.buttons-row {
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 12px 12px 12px;
  background-color: white;
  border-radius: 8px;
  .cancel {
    background-color: transparent !important;
    box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
    text-transform: none;
    padding: 12px;
    display: flex;
    align-items: center;
    height: 40px;
  }
}
.pipeline-header {
  margin-top: 48px;
  width: 30%;
  p {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
  }
}
.inputFields {
  font-size: 14px;
  font-family: Inter;
  line-height: 1.2;
  gap: 8px 24px;
  display: flex;
  flex-flow: row wrap;
}
.stageSelector {
  font-size: 14px;
  font-family: Inter;
  line-height: 1.2;
  width: 100%;
  display: flex;
  width: 100%;
  display: flex;
}
.stageSelector > input {
  margin: 0;
  padding: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.stageSelector > * {
  margin-right: 16px;
}
.arrowLabel {
  width: 173px;
  cursor: pointer;
  height: 40px;
  display: flex;
  position: relative;
  font-size: 12px;
  background: #e6e8ec;
  align-items: center;
  justify-content: center;
}
.stageSelector > input:checked + label:before {
  border-left-color: #75787a;
}

.arrowLabel:before {
  right: -10px;
  z-index: 2;
}

.arrowLabel:before {
  width: 0;
  bottom: 0;
  height: 0;
  content: "";
  position: absolute;
  border-top: 20px solid transparent;
  border-left: 10px solid #e6e8ec;
  border-bottom: 20px solid transparent;
}
.stageSelector > input:checked + label {
  color: #ffffff;
  background: #75787a;
}
.panel-wrapper {
  position: absolute;
  right: 0;
  top: 0%;
  bottom: 0%;
  background-color: #ffffff;
  padding: 16px;
  min-width: 720px;
  z-index: 102;
  max-width: 720px;
  border: 1px solid #e6e8ec;
  overflow-y: scroll;
}
.row {
  gap: 24px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 101;
}

.top-row {
  width: 100%;
  justify-content: flex-end;
  padding: 12px;
}
.close-button {
  background: #f4f5f7;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.divider {
  width: 100%;

  border: 1px solid #e6e8ec;
  margin-bottom: 24px;
  margin-top: 16px;
  left: 0;
  right: 0;
}
.description {
  padding-left: 24px;
  padding-top: 0px;
  padding-bottom: 24px;
  padding-right: 56px;
  border: none !important;
  outline: none !important;
  width: 100%;
  resize: none;
}
.main-wrapper {
  margin: 12px;
  border: 1px solid #e6e8ec;
  border-radius: 8px;
}
.sub-block {
  padding: 0px;
  border-bottom: 1px solid #e6e8ec;
}
.sub-block-wrapper {
  padding: 12px;
}
.no-active {
  height: 40px;
  max-width: 65%;
}
.textarea {
  width: 100%;
  height: 110px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #e6e8ec;
}
.textarea:focus {
  border: 1px solid #e6e8ec;
  outline: none;
  border-radius: 8px;
}
.no-active.v-input.v-input__slot {
  border-radius: 20px;
  border: 1px solid #e6e8ec !important;
}
.v-text-field--outlined >>> fieldset {
  border: 1px solid #e6e8ec;
}
.probability {
  padding-right: 20%;
}
.customer-row {
  border-radius: 8px;
  margin-bottom: 8px;
  background-color: #ffffff;
}
.sub-heading {
  padding: 24px;
  padding-right: 56px;
}
.edit-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  height: 32px;
  gap: 8px;
  /* grey/grey500 */

  border: 1px solid #e6e8ec;
  box-sizing: border-box;
  border-radius: 8px;
  img {
    width: 16px;
    height: 16px;
  }
  &:hover {
    background-color: #e7e7e7;
    cursor: pointer;
  }
}
.edit-button-text {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 0 !important;
}
.vertical-line {
  background-color: #e6e8ec;
  width: 1px;
  height: 16px;
}
.description-item {
  border: none !important;
  outline: none !important;
  width: 592px;
  height: 40px;
  resize: none;
}
.activities {
  padding-left: 24px;
  padding-right: 56px;
  padding-bottom: 24px;
}
.modal-open {
  overflow-x: hidden;
  overflow-y: hidden;
}
.modal-close {
  overflow-x: auto;
  overflow-y: auto;
}
.object-link:hover {
  color: black;
  text-decoration: underline;
  cursor: pointer;
}
.save {
  background-color: #0aaf60;
  color: white;
  border-color: #e6e8ec;
}
.contactperson {
  font-size: 14px !important;
  line-height: 16px;
  &:hover {
    text-decoration: underline;
  }
}
</style>
