<template>
  <div class="notes-main" v-if="notes">
    <h5 v-if="title" style="padding-left: 0; padding-bottom: 24px">
      {{ $t("Notes") }}
      <button
          v-if="!hideButton"
          @click="isOpen = !isOpen"
          type="button"
          class="add-notes"
      >
        <img
            style="padding-left: 6px"
            src="../../assets/images/plus.svg"
            alt=""
        />
        <span class="add-note-inside">{{ $t("AddNote") }}</span>
      </button>
    </h5>
    <template v-if="!loading">
      <div
          class="note-row-container"
          v-for="(note, index) in allNotes"
          :key="note.id"
      >
        <div
            v-bind:class="{
          'note-row-first': index === 0 && index !== allNotes.length - 1,
          'note-row': 0 < index < allNotes.length - 1,
          'note-row-last': index !== 0 && index === allNotes.length - 1,
          'note-row-only': index === 0 && index === allNotes.length - 1,
        }"
            v-if="note.statusCode !== 0"
        >
          <div class="logo-and-text">
            <div class="note-icon"></div>
            <div style="height: 100%">
              <v-row>
              <span
                  v-if="note.isPublic && !publicDisabled"
                  class="public-class"
              >{{ $t("Public").toUpperCase() }}</span
              >
                <span
                    v-if="!note.isPublic && !publicDisabled"
                    class="private-class"
                    style="font-weight: bold"
                >{{ $t("Private").toUpperCase() }}</span
                >
                <span
                    v-if="note.createdAt"
                    class="private-class"
                    v-bind:class="{
                  'private-class-gap': !publicDisabled,
                  'private-class-gap-disabled': publicDisabled,
                }"
                >
                <p v-if="!publicDisabled">•</p>
                {{ formatDate(note.createdAt) }}</span
                >
              </v-row>
              <v-row :key="'note-' + index + '-' + htmlUpdater">
                <p
                    @click="openField(note)"
                    v-if="note.isDisabled"
                    class="note-text"
                >
                  {{ note.content }}
                </p>
                <v-textarea
                    auto-grow
                    ref="noteEditTextarea"
                    hide-details
                    v-if="!note.isDisabled"
                    class="note-text text-area"
                    @focusout="
                  closeField(note);
                "
                    outlined
                    dense
                    rows="1"
                    v-model="note.content"
                >
                </v-textarea>
              </v-row>
            </div>
          </div>
          <v-menu
              :close-on-content-click="true"
              :nudge-width="124"

              bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="outputs-wrapper" style="height:24px">
                <img
                    :id="'dropdown-button-' + note.id"
                    src="@/assets/images/dot_menu_grey.svg"
                    alt=""
                    @click="selectedNote = note"
                />
              </div>
            </template>
            <div class="outputs-modal" style="background-color: white; border-radius:8px; padding:12px">
              <div v-if="selectedNote">
                <div
                    v-if="!publicDisabled"
                    class="popup-upper"
                    style="gap:12px; margin-bottom:20px"
                    @click="changeNoteStatus(selectedNote)"
                >
                  <img
                      v-if="!selectedNote.isPublic"
                      src="@/assets/images/toggle-inactive-20x20.svg"
                      alt=""
                  />
                  <img
                      v-if="selectedNote.isPublic"
                      class="toggle-img"
                      src="@/assets/images/toggle-active-24x24.svg"
                      alt=""
                  />
                  <p class="content-small">{{ $t("PublicEntry") }}</p>
                </div>
                <v-row style="cursor:pointer; gap:12px" @click="deleteNote(selectedNote)">
                  <img src="../../assets/images/red_trash_20_20.svg" alt=""/>
                  <p style="color: red" class="content-small">{{ $t("Delete") }}</p>
                </v-row>
              </div>
            </div>
          </v-menu>
        </div>
      </div>
    </template>
    <div style="display: flex; justify-content: center; align-items: center;">
    <div
        v-if="loading"
        class="loader"
    ></div>
    </div>
    <v-row
        v-if="(isOpen || isAccordion) && !loading"
        style="padding-top: 24px; padding-bottom: 0"
    >
      <div
          :class="{ 'new-note-margin': allNotes.length > 0 }"
          style="
          display: flex;
          gap: 0;
          width: 100%;
          height: 38px;
          align-content: center;
        "
      >
        <v-textarea
            @focusout="closeNoteField()"
            dense
            auto-grow
            outlined
            hide-details
            ref="noteAddTextarea"
            :placeholder="$t('AddNote') + '...'"
            textarea
            rows="1"
            class="add-field"
            v-model="newNote.content"
        >{{ newNote }}
        </v-textarea>
      </div>
    </v-row>
  </div>
</template>

<script>
import nanoid from "nanoid";

export default {
  name: "Notes",
  props: [
    "notes",
    "newNote",
    "title",
    "isAccordion",
    "clientSidepanelOpen",
    "hideButton",
    "publicDisabled",
    "loading",
  ],
  data: () => ({
    isOpen: false,
    selectedNote: null,
    allNotes: null,
    dropdownId: null,
    updatingNote: null,
    htmlUpdater: 0,
  }),
  created() {
    this.allNotes = this.notes;
    console.log("ALL Notes", this.allNotes);
    this.assignId();
  },
  watch: {
    notes() {
      this.allNotes = this.notes;
    },
    isAccordion(value) {
      if (value) {
        this.$nextTick(() => {
          this.$refs.noteAddTextarea.focus()
        })
      }
    },
    isOpen(value) {
      if (value) {
        this.$nextTick(() => {
          this.$refs.noteAddTextarea.focus();
        })
      }
    }
  },
  computed: {},
  methods: {
    assignId() {
      this.allNotes = this.notes;
      this.allNotes.forEach((note) => {
        if (!note.id) {
          note.id = nanoid(8);
        }
      });
    },
    openField(note) {
      console.log("before updated", note.isDisabled);
      note.isDisabled = false;
      console.log("updated", note.isDisabled)
      console.log(note);
      this.htmlUpdater++;
      this.updatingNote = JSON.parse(JSON.stringify(note));
      console.log(this.$refs);
      this.$nextTick(() => {
        try {
          console.log(this.$refs);
          this.$refs.noteEditTextarea[0].focus();
        } catch (e) {
          note.isDisabled = true;
          console.log(e);
        }
      })
    },
    closeField(note) {
      note.isDisabled = true;
      this.htmlUpdater++;
      if (this.updatingNote.content !== note.content) {
        this.saveNotes();
      }
      this.updatingNote = null;
    },
    closeFields() {
      this.isOpen = false;
      this.notes.forEach((e) => {
        e.isDisabled = true;
      });
      this.$emit("closeField");
      this.$forceUpdate();
    },
    addNewNote() {
      this.newNote.id = nanoid(8);
      this.$emit("newNote", this.newNote);
      console.log("NOTE COMPONENT", this.newNote);
      this.$forceUpdate();
    },
    changeNoteStatus(selectedNote) {
      selectedNote.isPublic = !selectedNote.isPublic;

      this.allNotes.forEach((note, index) => {
        if (note.id === selectedNote.id) {
          this.allNotes[index] = selectedNote;
        }
      });
      this.saveNotes();
    },
    deleteNote(selectedNote) {
      selectedNote.content = "";
      this.saveNotes();
    },
    saveNotes() {
      this.$emit("saveNotesChild", this.allNotes);
    },
    closeNoteField() {
      this.isOpen = false;
      this.$emit("closeNoteField");
      if (this.newNote.content === "") {
        return;
      }
      this.addNewNote();
      this.saveNotes();
    },
    formatDate(e) {
      const allDate = e.split(" ");
      const thisDate = allDate[0].split("/");
      return [thisDate[0], thisDate[1], thisDate[2]].join(".");
    },
  },
};
</script>

<style lang="scss" scoped>
.notes-main {
  padding: 0;
}

.add-notes {
  flex-direction: row;
  display: flex;
  align-items: center;
  padding-right: 8px;
  font-weight: 400;
  font-family: Inter, serif;
  font-size: 12px;
  line-height: 16px;
  border-radius: 8px;
  height: 28px;
  justify-content: space-between;
  border: 1px solid #e6e8ec;
  margin-left: 16px;
}

.add-note-inside {
  height: 16px;
  margin-left: 8px;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
}

.new-note-row {
  display: flex;
  gap: 8px;
  width: 100%;
  align-content: center;
}

.note-row-container {
  height: 100%;
}

.note-row {
  margin-bottom: 20px;
  flex-flow: row;
  margin-top: 16px !important;
  cursor: pointer;
  max-width: 100%;
  display: flex;
  min-width: 100%;
  height: 100%;
  justify-content: space-between;

}

.note-row-only {
  margin-bottom: 0;
  flex-flow: row;
  margin-top: 0 !important;
  cursor: pointer;
  max-width: 100%;
  display: flex;
  min-width: 100%;
  height: 100%;
  justify-content: space-between;
}

.note-row-first {
  margin-bottom: 20px;
  flex-flow: row;
  margin-top: 0 !important;
  cursor: pointer;
  max-width: 100%;
  display: flex;
  min-width: 100%;
  height: 100%;
  justify-content: space-between;

}

.note-row-last {
  margin-bottom: 0;
  flex-flow: row;
  margin-top: 16px !important;
  cursor: pointer;
  max-width: 100%;
  display: flex;
  min-width: 100%;
  height: 100%;
  justify-content: space-between;

}

.logo-and-text {
  display: flex;
  gap: 8px;
  width: 70%;
  height: 100%;
}

.note-icon {
  background-image: url("../../assets/images/noteBlue.svg");
  min-width: 32px;
  height: 32px;
  cursor: auto;
}

.public-class {
  color: #0aaf60;
  font-size: 10px;
  font-weight: bold;
}

.private-class {
  font-size: 10px;
  color: #75787a;
  display: flex;
}

.private-class-gap {
  padding-left: 3px;
  gap: 3px;
}

.private-class-gap-disabled {
  gap: 3px;
}

.createdAt {
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;
  margin-bottom: 0 !important;

  /* grey/grey900 */

  color: #939597;
}

.note-text {
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  font-family: Inter, serif;
  margin-top: -14px;
}

.note-text:hover {
  cursor: pointer;
  /*
  box-shadow: inset 0px 0px 0px 1px rgb(230, 232, 236);
*/
  border-radius: 8px;
}

.text-area {
  height: fit-content !important;
  margin-bottom: 0 !important;
  padding-right: 16px;
}

.dropdown-container {
  display: flex;
  justify-content: space-between;
}

.dropdown-content {
  display: none;
  margin-left: 80px;
  position: absolute;
  background-color: white;
  width: 180px;
  max-width: 180px;
  overflow: auto;
  z-index: 5555;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04), 0 1px 2px rgba(0, 0, 0, 0.03),
  0 0 1px rgba(0, 0, 0, 0.04);

  p {
    padding: 12px 12px 12px 12px;
    font-size: 14px;
    line-height: 16px;

    &:hover {
      background-color: #f4f5f7;
      cursor: pointer;
    }
  }
}

.toggle-img {
  height: 20px;
  width: 20px;
}

.popup-upper {
  cursor: pointer;
  justify-content: left;
  display: flex;
  align-items: center;
  /* &:hover {
    background-color: #f4f5f7;
    cursor: pointer;
  } */
}

.popup-lower {
  cursor: pointer;
  justify-content: left;
  padding-top: 2px;
  padding-left: 14px;
  padding-bottom: 6px;

  &:hover {
    background-color: #f4f5f7;
    cursor: pointer;
  }
}

.trash-icon {
  height: 20px;
  width: 20px;
  margin: auto 0;
}

.outlined-small-button {
  margin-left: 24px;
  height: 32px;
  width: 34px;
  justify-content: center;
  align-items: center;
  border: 0;

  &:hover {
    background-color: #f4f5f7;
  }
}

.add-field-container {
  display: flex;
  gap: 8px;
  width: 70%;
  align-content: center;
}

.add-field {
  margin-bottom: 0 !important;
  padding-bottom: 14px;
  width: 100% !important;
}

.new-note-margin {
  margin-bottom: 32px;
}
</style>
