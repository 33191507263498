var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.notes ? _c('div', {
    staticClass: "notes-main"
  }, [_vm.title ? _c('h5', {
    staticStyle: {
      "padding-left": "0",
      "padding-bottom": "24px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Notes")) + " "), !_vm.hideButton ? _c('button', {
    staticClass: "add-notes",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.isOpen = !_vm.isOpen;
      }
    }
  }, [_c('img', {
    staticStyle: {
      "padding-left": "6px"
    },
    attrs: {
      "src": require("../../assets/images/plus.svg"),
      "alt": ""
    }
  }), _c('span', {
    staticClass: "add-note-inside"
  }, [_vm._v(_vm._s(_vm.$t("AddNote")))])]) : _vm._e()]) : _vm._e(), !_vm.loading ? _vm._l(_vm.allNotes, function (note, index) {
    return _c('div', {
      key: note.id,
      staticClass: "note-row-container"
    }, [note.statusCode !== 0 ? _c('div', {
      class: {
        'note-row-first': index === 0 && index !== _vm.allNotes.length - 1,
        'note-row': 0 < index < _vm.allNotes.length - 1,
        'note-row-last': index !== 0 && index === _vm.allNotes.length - 1,
        'note-row-only': index === 0 && index === _vm.allNotes.length - 1
      }
    }, [_c('div', {
      staticClass: "logo-and-text"
    }, [_c('div', {
      staticClass: "note-icon"
    }), _c('div', {
      staticStyle: {
        "height": "100%"
      }
    }, [_c('v-row', [note.isPublic && !_vm.publicDisabled ? _c('span', {
      staticClass: "public-class"
    }, [_vm._v(_vm._s(_vm.$t("Public").toUpperCase()))]) : _vm._e(), !note.isPublic && !_vm.publicDisabled ? _c('span', {
      staticClass: "private-class",
      staticStyle: {
        "font-weight": "bold"
      }
    }, [_vm._v(_vm._s(_vm.$t("Private").toUpperCase()))]) : _vm._e(), note.createdAt ? _c('span', {
      staticClass: "private-class",
      class: {
        'private-class-gap': !_vm.publicDisabled,
        'private-class-gap-disabled': _vm.publicDisabled
      }
    }, [!_vm.publicDisabled ? _c('p', [_vm._v("•")]) : _vm._e(), _vm._v(" " + _vm._s(_vm.formatDate(note.createdAt)))]) : _vm._e()]), _c('v-row', {
      key: 'note-' + index + '-' + _vm.htmlUpdater
    }, [note.isDisabled ? _c('p', {
      staticClass: "note-text",
      on: {
        "click": function ($event) {
          return _vm.openField(note);
        }
      }
    }, [_vm._v(" " + _vm._s(note.content) + " ")]) : _vm._e(), !note.isDisabled ? _c('v-textarea', {
      ref: "noteEditTextarea",
      refInFor: true,
      staticClass: "note-text text-area",
      attrs: {
        "auto-grow": "",
        "hide-details": "",
        "outlined": "",
        "dense": "",
        "rows": "1"
      },
      on: {
        "focusout": function ($event) {
          return _vm.closeField(note);
        }
      },
      model: {
        value: note.content,
        callback: function ($$v) {
          _vm.$set(note, "content", $$v);
        },
        expression: "note.content"
      }
    }) : _vm._e()], 1)], 1)]), _c('v-menu', {
      attrs: {
        "close-on-content-click": true,
        "nudge-width": 124,
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var on = _ref.on,
            attrs = _ref.attrs;
          return [_c('div', _vm._g(_vm._b({
            staticClass: "outputs-wrapper",
            staticStyle: {
              "height": "24px"
            }
          }, 'div', attrs, false), on), [_c('img', {
            attrs: {
              "id": 'dropdown-button-' + note.id,
              "src": require("@/assets/images/dot_menu_grey.svg"),
              "alt": ""
            },
            on: {
              "click": function ($event) {
                _vm.selectedNote = note;
              }
            }
          })])];
        }
      }], null, true)
    }, [_c('div', {
      staticClass: "outputs-modal",
      staticStyle: {
        "background-color": "white",
        "border-radius": "8px",
        "padding": "12px"
      }
    }, [_vm.selectedNote ? _c('div', [!_vm.publicDisabled ? _c('div', {
      staticClass: "popup-upper",
      staticStyle: {
        "gap": "12px",
        "margin-bottom": "20px"
      },
      on: {
        "click": function ($event) {
          return _vm.changeNoteStatus(_vm.selectedNote);
        }
      }
    }, [!_vm.selectedNote.isPublic ? _c('img', {
      attrs: {
        "src": require("@/assets/images/toggle-inactive-20x20.svg"),
        "alt": ""
      }
    }) : _vm._e(), _vm.selectedNote.isPublic ? _c('img', {
      staticClass: "toggle-img",
      attrs: {
        "src": require("@/assets/images/toggle-active-24x24.svg"),
        "alt": ""
      }
    }) : _vm._e(), _c('p', {
      staticClass: "content-small"
    }, [_vm._v(_vm._s(_vm.$t("PublicEntry")))])]) : _vm._e(), _c('v-row', {
      staticStyle: {
        "cursor": "pointer",
        "gap": "12px"
      },
      on: {
        "click": function ($event) {
          return _vm.deleteNote(_vm.selectedNote);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("../../assets/images/red_trash_20_20.svg"),
        "alt": ""
      }
    }), _c('p', {
      staticClass: "content-small",
      staticStyle: {
        "color": "red"
      }
    }, [_vm._v(_vm._s(_vm.$t("Delete")))])])], 1) : _vm._e()])])], 1) : _vm._e()]);
  }) : _vm._e(), _c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_vm.loading ? _c('div', {
    staticClass: "loader"
  }) : _vm._e()]), (_vm.isOpen || _vm.isAccordion) && !_vm.loading ? _c('v-row', {
    staticStyle: {
      "padding-top": "24px",
      "padding-bottom": "0"
    }
  }, [_c('div', {
    class: {
      'new-note-margin': _vm.allNotes.length > 0
    },
    staticStyle: {
      "display": "flex",
      "gap": "0",
      "width": "100%",
      "height": "38px",
      "align-content": "center"
    }
  }, [_c('v-textarea', {
    ref: "noteAddTextarea",
    staticClass: "add-field",
    attrs: {
      "dense": "",
      "auto-grow": "",
      "outlined": "",
      "hide-details": "",
      "placeholder": _vm.$t('AddNote') + '...',
      "textarea": "",
      "rows": "1"
    },
    on: {
      "focusout": function ($event) {
        return _vm.closeNoteField();
      }
    },
    model: {
      value: _vm.newNote.content,
      callback: function ($$v) {
        _vm.$set(_vm.newNote, "content", $$v);
      },
      expression: "newNote.content"
    }
  }, [_vm._v(_vm._s(_vm.newNote) + " ")])], 1)]) : _vm._e()], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }