<template>
  <div>
    <Header
      :initFilterCondition="initFilterCondition"
      @openProjectSettings="openProjectSettings"
      :project-settings-panel-status="projectSettingsPanelStatus"
    />
    <div
      v-if="projectSettingsOpened"
      id="project-settings-header"
      class="project-settings-header"
    >
      <div style="margin-right: 32px">
        <p class="content-small-semibold" style="color: white">
          {{ this.$t("ConfigureProjectTile") }}
        </p>
      </div>
      <div class="toggles" style="display: flex">
        <BaseToggle
            v-model="user.preferences.projects.projectName.enabled"
            @click="changeProjectSettings($event, 'projectName')"
            :label="$t('ProjectName')"
            :labelColour="'white'"
        />
        <div v-if="user.preferences.projects.projectName.enabled" class="vertical-separator"></div>
          <BaseToggle
            v-if="user.preferences.projects.projectName.enabled"
            v-model="user.preferences.projects.projectName.shortAddress.enabled"
            @click="changeProjectSettings($event, 'projectName.shortAddress')"
            :label="$t('ShortAddress')"
            :labelColour="'white'"
        />
        <div class="vertical-separator"></div>
        <BaseToggle
            v-model="user.preferences.projects.clientName.enabled"
            @click="changeProjectSettings($event, 'clientName')"
            :label="$t('Contact')"
            :labelColour="'white'"
        />
        <div class="vertical-separator"></div>
        <BaseToggle
            v-model="user.preferences.projects.brokerageFee.enabled"
            @click="changeProjectSettings($event, 'brokerageFee')"
            :label="$t('BrokerageFee')"
            :labelColour="'white'"
        />
        <div class="vertical-separator"></div>
        <BaseToggle
            v-model="user.preferences.projects.projectValue.enabled"
            @click="changeProjectSettings($event, 'projectValue')"
            :label="$t('ProjectValue')"
            :labelColour="'white'"
        />
        <div class="vertical-separator"></div>
        <BaseToggle
            v-model="user.preferences.projects.additionalInformation.enabled"
            @click="changeProjectSettings($event, 'additionalInformation')"
            :label="$t('AdditionalInfo')"
            :labelColour="'white'"
        />
      </div>
      <div class="vertical-separator"></div>
      <div @click="closeProjectSettingsPanel()" class="close-button">
        <img src="../../assets/images/close.svg" class="white-image" alt="" />
        <p class="button-small" style="color: white">{{ this.$t("Close") }}</p>
      </div>
    </div>
    <Content
      @reloadKanban="getProjectsForPipelineByFilters"
      :pipelinesWithUsableDeals="projects"
      @getProjectsForPipeline="getProjectsForPipelineByFilters"
    />
    <SidepanelDetail v-if="sidepanelDeal" />
  </div>
</template>

<script>
import Vue from "vue";
import Header from "../../components/Deals/Header.vue";
import Content from "../../components/Deals/Content.vue";
import SidepanelDetail from "@/components/Deals/SidepanelDetail";
import { mapGetters, mapActions } from "vuex";
import BaseToggle from "../../components/common/BaseToggle";
import axios from "axios";

export default {
  name: "Deals",
  components: {
    Header,
    Content,
    SidepanelDetail,
    BaseToggle,
  },
  data() {
    return {
      filteredDeals: [],
      pipelinesWithUsableDeals: null,
      allDeals: [],
      projectSettingsOpened: false,
      projectSettingsPanelStatus: 0,
      initFilterCondition: [],
      getProjectsLimit: 25,
      projects: {
        qualified: {
          deals: [],
          page: 1,
          count: 0,
          brokerageFee: 0,
          loading: false,
          allLoaded: false,
        },
        contactMade: {
          deals: [],
          page: 1,
          count: 0,
          brokerageFee: 0,
          loading: false,
          allLoaded: false,
        },
        preparation: {
          deals: [],
          page: 1,
          count: 0,
          brokerageFee: 0,
          loading: false,
          allLoaded: false,
        },
        review: {
          deals: [],
          page: 1,
          count: 0,
          brokerageFee: 0,
          loading: false,
          allLoaded: false,
        },
        Finished: {
          deals: [],
          page: 1,
          count: 0,
          brokerageFee: 0,
          loading: false,
          allLoaded: false,
        },
        Done: {
          deals: [],
          page: 1,
          count: 0,
          brokerageFee: 0,
          loading: false,
          allLoaded: false,
        },
        archived: {
          deals: [],
          page: 1,
          count: 0,
          brokerageFee: 0,
          loading: false,
          allLoaded: false,
        },
      },
    };
  },
  async created() {
    if (this.user && !this.user.preferences.projects) {
       const response = await axios.get(
        `/api/user/project-preference/${this.user._id}`
      );
      this.user.preferences = response.data.user.preferences
      this.setUser(this.user);
    }
    await this.getCompanyPolicies();
    this.initFilter();
  },

  mounted() {},
  computed: {
    ...mapGetters([
      "sidepanelDealsAdd",
      "sidepanelDeal",
      "projectsLoaded",
      "activeProjectsTab",
      "provisionalFilters",
      "provisionalFiltersUpdater",
      "filters",
      "user",
      "users"
    ]),
    colleagues() {
      if (this.user.isAdmin || this.user.companyPolicies.filters.projects.showAll) {
        return [
          {name: this.$t("AllBrokers"), value: "allBrokers", _id: "allBrokers"},
        ].concat(this.users)
      } else {
        let users = this.users.filter((user) => user.adminAccess);
        if (users.length > 0) {
          return [
            {name: this.$t("AllBrokers"), value: "allBrokers", _id: "allBrokers"},
          ].concat(users)
        } else {
          return []
        }
      }
    }
  },
  methods: {
    ...mapActions([
      "changeSidepanelStateDeals",
      "changeSidepanelStateDeal",
      "setProjectsLoaded",
      "setProvisionalFilter",
      "getCompanyPolicies",
      "setUser"
    ]),
    openProjectSettings(value) {
      this.projectSettingsOpened = value;
    },
    closeProjectSettingsPanel() {
      this.projectSettingsOpened = false;
      this.projectSettingsPanelStatus++;
    },
    async changeProjectSettings(event, item) {
      await axios.post(`/api/admin/project-settings/update/${this.user._id}`, {
        item: item,
        itemValue: event,
      });
    },
    initFilter() {
      this.savedViewId = this.$route.params.savedViewId;
      if (this.savedViewId) {
        this.initFilterCondition = this.filters["projects"].find(
          (item) => item._id === this.savedViewId
        ).conditions;
      } else {
        const filter = this.filters["projects"].find(
            (item) => item.isDefault
        )
        this.initFilterCondition = filter.conditions;
        const brokerField = this.initFilterCondition.find((condition) => condition.name === "brokerId")
        if (this.user.access.accessLevel === "company_admin") {
          if (filter.name === "allValues") {
            brokerField.value = null;
            filter.filterTitle = "AllProjects";
          }
        } else {
          if (filter.name === "allValues") {
            brokerField.value = {
              type: "user",
              value: this.user._id
            }
            filter.filterTitle = "MyProjects";
          }
        }
      }
    },
    getProjectsPipelineCountsByFilters() {
      axios
        .post("/api/projects/counts", {
          filters: this.provisionalFilters["projects"],
        })
        .then((response) => {
          if (response.data) {
            for (const pipeline of Object.keys(this.projects)) {
              const data = response.data[pipeline] || {
                itemCount: 0,
                totalBrokerageFee: 0,
              };        
              this.projects[pipeline]["count"] = data.itemCount;
              this.projects[pipeline]["brokerageFee"] = data.totalBrokerageFee;
            }
          }
        });
    },
    getProjectsByFilters() {
      for (const pipeline of Object.keys(this.projects)) {
        let url = `/api/projects/${pipeline}/1`;
        axios
          .post(url, { filters: this.provisionalFilters["projects"] })
          .then((response) => {
            this.projects[pipeline].allLoaded =
              response.data.length === 0 ||
              response.data.length < this.getProjectsLimit;

            let deals = response.data;

            deals.forEach((deal) => {
              let amlFiles = [];
              deal.aml.forEach((aml) => {
                amlFiles.push(...aml.files);
              });
              amlFiles = amlFiles.map((file) => {
                return {
                  fileName: file.name,
                  uploaderName: file.creator.name,
                  amlId: file.amlId,
                  _id: file._id,
                };
              });
              amlFiles = amlFiles.filter(
                (v, i, a) => a.findIndex((v2) => v2._id === v._id) === i
              );
              deal.files.push(...amlFiles);
            });
            Vue.set(this.projects[pipeline], "deals", deals);
          });
      }
    },
    async getProjectsForPipelineByFilters(pipeline, reload = false) {
      if (this.projects[pipeline].allLoaded && !reload) return;
      this.projects[pipeline].page += 1;
      let url = `/api/projects/${pipeline}/${this.projects[pipeline].page}`;
      axios
        .post(url, { filters: this.provisionalFilters["projects"] })
        .then((response) => {
          this.projects[pipeline].allLoaded =
            response.data.length === 0 ||
            response.data.length < this.getProjectsLimit;
            let updatedPipeline
            if (reload) {
              updatedPipeline = response.data
              this.projects[pipeline]["count"] = 0;
              this.projects[pipeline]["deals"] = [];
            } else {
               updatedPipeline = this.projects[pipeline].deals.concat(
                response.data
              );
            }
          this.projects[pipeline].loading = false;
          Vue.set(this.projects[pipeline], "deals", updatedPipeline);
        });
    },
    async setupDeals() {
      await this.changeSidepanelStateDeals(0);
      if (!this.$route.params.id) {
        await this.changeSidepanelStateDeal(0);
        window.history.replaceState({}, document.title, "/projects");
      } else {
        await this.changeSidepanelStateDeal(1);
      }
    },
  },
  watch: {
    async dealsLoaded() {
      if (this.dealsLoaded) {
        await this.setupDeals();
      }
    },
    async provisionalFiltersUpdater() {
      await this.setProjectsLoaded(false);
      for (const pipeline of Object.keys(this.projects)) {
        this.projects[pipeline].allLoaded = false;
        this.projects[pipeline].page = 1;
      }
      if (!(this.provisionalFilters["projects"].find((condition) => condition.name === "months").value).length && this.provisionalFilters["projects"].find((condition) => condition.name === "months").value !== ''){
        this.provisionalFilters["projects"].find((condition) => condition.name === "months").value = ""
      }
      this.getProjectsByFilters();
      this.getProjectsPipelineCountsByFilters();
      await this.setProjectsLoaded(true);
    },
  },
};
</script>

<style lang="scss">
.project-settings-header {
  display: flex;
  z-index: 2;
  height: 56px;
  width: 100%;
  background-color: black;
  padding-left: 104px;
  color: white;
  align-items: center;
}

.white-image {
  filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(317deg)
    brightness(106%) contrast(101%);
}

.vertical-separator {
  height: 24px;
  width: 1px;
  background: rgba(117, 120, 122, 0.5);
  margin-left: 16px;
  margin-right: 16px;
}

.close-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 6px;
  gap: 6px;
  width: 66px;
  height: 24px;
  border: 1px solid #e6e8ec;
  border-radius: 6px;
  cursor: pointer;
}
</style>
