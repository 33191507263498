var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "deal-grid-content",
    attrs: {
      "id": "projects-kanban-content"
    }
  }, _vm._l(_vm.filteredPipelinesWithDeals, function (value, key) {
    return _c('div', {
      key: key,
      staticClass: "deal-grid-container"
    }, [_c('div', {
      staticClass: "deal-pipeline"
    }, [_c('div', [_c('div', {
      staticClass: "pipeline-name-count-row",
      staticStyle: {
        "display": "flex",
        "justify-content": "space-between"
      }
    }, [_c('div', {
      staticStyle: {
        "align-items": "center",
        "padding-right": "8px",
        "display": "flex"
      }
    }, [_c('h6', [_vm._v(" " + _vm._s(_vm.$t(`ProjectPipeline.${key}`)) + " ")]), _c('div', {
      staticStyle: {
        "margin-left": "4px"
      }
    }, [_c('h6', {
      staticClass: "pipeline-count"
    }, [_vm._v("(" + _vm._s(value.count) + ")")])])]), key === 'Done' && value.count > 0 ? _c('BaseButton', {
      staticStyle: {
        "max-height": "16px"
      },
      attrs: {
        "display-key": 'name',
        "display-value": 'value',
        "items": _vm.DoneDonePipelineDropdown,
        "size": 'icon',
        "is-loading": _vm.archiveDealsLoader,
        "icon-left": 'archive_button24_24.svg'
      },
      on: {
        "onConfirm": function ($event) {
          return _vm.archiveDeals(value);
        }
      }
    }) : _vm._e()], 1), _c('v-row', {
      staticClass: "pipeline-sub-info",
      staticStyle: {
        "height": "16px",
        "margin-top": "4px"
      }
    }, [value.brokerageFee ? _c('h6', {
      class: {
        'orange-color': key !== 'Done',
        'green-color': key === 'Done'
      }
    }, [_vm._v(" " + _vm._s(_vm.$nMapper.price(value.brokerageFee.toFixed(0), "€")) + " ")]) : _vm._e()])], 1), _c('draggable', {
      key: _vm.taskUpdater,
      staticClass: "scrollable",
      attrs: {
        "list": value.deals,
        "animation": 200,
        "ghost-class": "ghost-card",
        "drag-class": "drag-card",
        "group": "tasks",
        "move": function (val) {
          return _vm.$emit('updatePipeline', val);
        },
        "id": 'drag-' + key
      },
      on: {
        "end": function () {
          return _vm.$emit('dragEnd');
        }
      }
    }, [_vm._l(value.deals, function (deal) {
      return _c('GridCard', {
        key: deal._id,
        staticStyle: {
          "margin-top": "4px"
        },
        attrs: {
          "deal": deal,
          "id": deal.pipelineStep
        },
        on: {
          "cardClick": function (dealId, event) {
            return _vm.$emit('cardClick', dealId, event);
          }
        }
      });
    }), value.loading ? _c('div', {
      staticStyle: {
        "width": "100%",
        "height": "120px",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center"
      }
    }, [_c('div', {
      staticClass: "loader"
    })]) : _vm._e()], 2)], 1)]);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }