var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "list-card-content",
    class: _vm.cardBorderColor,
    staticStyle: {
      "text-decoration": "unset",
      "display": "inherit"
    },
    attrs: {
      "href": _vm.path + '/' + _vm.deal._id + '/general'
    }
  }, [_c('div', {
    staticClass: "deal-list-card-content",
    on: {
      "click": function ($event) {
        return _vm.$emit('cardClick', _vm.deal._id, $event);
      }
    }
  }, [_c('div', {
    staticClass: "deal-image-con"
  }, [_vm.deal.images.length ? _c('img', {
    staticClass: "deal-image",
    attrs: {
      "src": _vm.setDealImages(_vm.deal.images),
      "alt": ""
    }
  }) : _vm._e(), !_vm.deal.images.length ? _c('span', {
    staticClass: "deal-image",
    staticStyle: {
      "background": "#f4f5f7"
    }
  }) : _vm._e()]), _c('div', {
    staticClass: "deal-main-info-con"
  }, [_c('div', {
    staticClass: "content-bold"
  }, [_vm._v(" " + _vm._s(_vm.deal.name) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.deal.contactPerson.contactName) + " ")]), _vm.deal.openHouse ? _c('OpenHouseContainer', {
    staticStyle: {
      "margin-top": "2px",
      "margin-left": "-4px"
    },
    attrs: {
      "task": _vm.deal.openHouse,
      "invert": true
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "deal-sub-info-con"
  }, [_c('span', [_c('DealTypeContainer', {
    attrs: {
      "deal-type": _vm.deal.dealType
    }
  }), _vm.deal.amount ? _c('span', {
    staticClass: "deal-amount-con"
  }, [_vm._v(" " + _vm._s(_vm.formatAmount()) + " ")]) : _vm._e()], 1)]), _vm.deal.pipelineStep !== 'Done' && _vm.deal.pipelineStep !== 'archived' ? _c('div', {
    staticClass: "deal-tasks-files-con",
    staticStyle: {
      "gap": "0px"
    }
  }, [_vm.deal.objects && _vm.deal.objects.length ? _c('div', {
    staticClass: "outputs-row"
  }, [_vm.isOutputModal ? _c('OutputsModal', {
    attrs: {
      "project": _vm.deal
    },
    on: {
      "show": _vm.isOutputsModalOpen
    }
  }) : _vm._e()], 1) : _vm._e()]) : _c('div', {
    staticClass: "deal-completed-con",
    staticStyle: {
      "min-width": "250px"
    }
  }, [_c('div', {
    staticClass: "content-small-semibold",
    class: _vm.dealWon ? 'deal-won' : 'deal-lost',
    staticStyle: {
      "align-items": "center",
      "display": "flex"
    }
  }, [_vm._v(" " + _vm._s(_vm.projectEndReason) + " ")]), _c('div', {
    staticClass: "content-small",
    class: _vm.dealWon ? 'deal-won' : 'deal-lost'
  }, [_vm._v(" " + _vm._s(_vm.deal.doneDate) + " ")])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }