<template>
  <a
    class="list-card-content"
    :class="cardBorderColor"
    :href="path + '/' + deal._id + '/general'"
    style="text-decoration: unset; display: inherit"
  >
    <div
      @click="$emit('cardClick', deal._id, $event)"
      class="deal-list-card-content"
    >
      <div class="deal-image-con">
        <img
          v-if="deal.images.length"
          class="deal-image"
          :src="setDealImages(deal.images)"
          alt=""
        />
        <span
          v-if="!deal.images.length"
          class="deal-image"
          style="background: #f4f5f7"
        />
      </div>
      <div class="deal-main-info-con">
        <div class="content-bold">
          {{ deal.name }}
        </div>
        <div>
          {{ deal.contactPerson.contactName }}
        </div>
        <OpenHouseContainer
          v-if="deal.openHouse"
          :task="deal.openHouse"
          :invert="true"
          style="margin-top: 2px; margin-left: -4px"
        />
      </div>
      <div class="deal-sub-info-con">
        <span>
          <DealTypeContainer :deal-type="deal.dealType" />
          <span v-if="deal.amount" class="deal-amount-con">
            {{ formatAmount() }}
          </span>
        </span>
      </div>
      <div
        class="deal-tasks-files-con"
        style="gap: 0px"
        v-if="deal.pipelineStep !== 'Done' && deal.pipelineStep !== 'archived'"
      >
        <!--  <div class="tasks-files-row">
          <span
              style="max-width:unset; min-width:64px"
              :class="'tasks-con ' + (!unfinishedCounter ? 'content-gray' : '')"
          >
            <img class="task-icon" src="../../assets/images/task.svg" alt=""/>
            <span>
              {{ countTasks() }}
            </span>
          </span>

          <span
              style="max-width:unset; min-width:64px"
              :class="'files-con ' + (!deal.files || (deal.files && !deal.files.length) ? 'content-gray' : '')"
          >
            <img
                class="task-icon"
                src="../../assets/images/attach.svg"
                alt=""
            />
            <span>
              {{ countFiles() }}
            </span>
          </span>
        </div> -->
        <div v-if="deal.objects && deal.objects.length" class="outputs-row">
          <OutputsModal
            v-if="isOutputModal"
            :project="deal"
            @show="isOutputsModalOpen"
          />
        </div>
      </div>
      <div class="deal-completed-con" v-else style="min-width: 250px">
        <div
          class="content-small-semibold"
          style="align-items: center; display: flex"
          :class="dealWon ? 'deal-won' : 'deal-lost'"
        >
          {{ projectEndReason }}
        </div>
        <div class="content-small" :class="dealWon ? 'deal-won' : 'deal-lost'">
          {{ deal.doneDate }}
        </div>
      </div>
    </div>
  </a>
</template>
<script>
import DealTypeContainer from "@/components/common/DealTypeContainer";
import OpenHouseContainer from "@/components/common/OpenHouseContainer";
import OutputsModal from "@/components/Listings/OutputsModal";
//import projectApi from "@/http/project";

export default {
  components: {
    DealTypeContainer,
    OpenHouseContainer,
    OutputsModal,
  },
  props: {
    deal: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    unfinishedCounter: 0,
    dealWon: false,
    isOutputModal: true,
    path: null,
  }),
  created() {
    //this.unfinishedTasks(this.deal);

    if (
      this.deal.endState &&
      this.deal.endState[this.deal.endState.length - 1].projectEndReason ===
        "Won"
    ) {
      this.dealWon = true;
    }
    this.path = document.URL;
  },
  mounted() {},
  computed: {
    cardBorderColor() {
      if (
        this.deal.pipelineStep === "Done" ||
        this.deal.pipelineStep === "archived"
      ) {
        return this.dealWon ? "deal-card-win-border" : "deal-card-lost-border";
      }
      return null;
    },
    projectEndReason() {
      if (
        this.deal.endState &&
        this.deal.endState[this.deal.endState.length - 1].projectEndReason &&
        this.$te(
          this.deal.endState[this.deal.endState.length - 1].projectEndReason
        )
      ) {
        return this.$t(
          this.deal.endState[this.deal.endState.length - 1].projectEndReason
        );
      }
      return this.$t("Lost");
    },
  },
  methods: {
    setDealImages(images) {
      let coverImage = [];
      images.forEach((image) => {
        if (image.coverImage) {
          coverImage.push(image.thumbnail || image.name);
        }
      });
      if (coverImage.length === 0) {
        return images[0].thumbnail || images[0].name;
      } else {
        return coverImage[0];
      }
    },
    /*  countTasks() {
      if (this.unfinishedCounter == 1) {
        return this.unfinishedCounter + " " + this.$t("Task");
      } else {
        return this.unfinishedCounter + " " + this.$t("Tasks1");
      }
    },
    countFiles() {
      if (this.deal.files.length == 1) {
        return this.deal.files.length + " " + this.$t("File");
      } else {
        return this.deal.files.length + " " + this.$t("Files1");
      }
    }, */
    /*  async unfinishedTasks(e) {
      this.unfinishedCounter = await projectApi.getUnfinishedTasksCount(e._id);
    }, */
    formatAmount() {
      const amount = this.deal.amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      let moneySign = "";
      switch (this.deal.currency) {
        case "EUR":
          moneySign = "€";
          break;
        case "USD":
          moneySign = "$";
          break;
      }

      return amount + " " + moneySign;
    },
    isOutputsModalOpen() {
      this.isOutputModal = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/css/DealCard";
@import "src/global_variables";

.list-card-content {
  border-radius: 8px;
  background-color: white;
}

.deal-list-card-content {
  padding: 0 32px 0 8px;
  cursor: pointer;
  display: flex;
}

.deal-image-con {
  padding: 8px;
  min-width: 88px;
}

.deal-image {
  width: 72px;
  height: 56px;
  border-radius: 8px;
  display: block;
}

.deal-main-info-con {
  min-width: 496px;
  display: grid;
  align-self: center;
  padding: 0 8px;
}

.deal-sub-info-con {
  min-width: 210px;
  min-height: 72px;
  display: flex;
  align-items: center;
  padding: 0 8px;
}

.tasks-files-row {
  min-width: 250px;
  display: flex;
  flex-direction: row;
  gap: 8px;
}
</style>
