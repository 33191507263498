var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [!_vm.projectsLoaded ? _c('div', {
    staticClass: "content-loading"
  }, [_c('div', {
    staticClass: "loader"
  })]) : Object.values(_vm.pipelinesWithUsableDeals).every(function (x) {
    return x.deals.length === 0;
  }) ? _c('div', {
    staticClass: "no-results-wrapper"
  }, [_c('p', {
    staticClass: "heading-geo32 no-results"
  }, [_vm._v(" " + _vm._s(_vm.$t("NoResultsFound")) + " ")]), _c('p', {
    staticClass: "clear-filters-button",
    on: {
      "click": _vm.removeFiltersProjects
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ClearAllFilters")) + " ")])]) : _c('div', [_vm.activeProjectsView === 'GridView' && _vm.activeProjectsTab !== 'archives' ? _c('GridContent', {
    attrs: {
      "pipelinesWithDeals": _vm.pipelinesWithUsableDeals,
      "taskUpdater": _vm.taskUpdater
    },
    on: {
      "getProjectsForPipeline": _vm.getProjectsForPipeline,
      "reloadKanban": function (value) {
        return _vm.$emit('reloadKanban', value, true);
      },
      "updatePipeline": _vm.updatePipeline,
      "dragEnd": function ($event) {
        _vm.previousTo = '';
      },
      "cardClick": _vm.cardClick
    }
  }) : _vm._e(), _vm.activeProjectsView === 'ListView' || _vm.activeProjectsTab === 'archives' ? _c('ListContent', {
    attrs: {
      "pipelinesWithDeals": _vm.pipelinesWithUsableDeals,
      "taskUpdater": _vm.taskUpdater
    },
    on: {
      "getProjectsForPipeline": _vm.getProjectsForPipeline,
      "reloadKanban": function (value) {
        return _vm.$emit('reloadKanban', value, true);
      },
      "updatePipeline": _vm.updatePipeline,
      "cardClick": _vm.cardClick
    }
  }) : _vm._e()], 1), _vm.endProjectConfirmationModalOpen ? _c('ConfirmationModal', {
    on: {
      "onConfirm": function ($event) {
        return _vm.openProject('openProjectEnd');
      },
      "onCancel": function ($event) {
        _vm.endProjectConfirmationModalOpen = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ConfirmProjectComplete")) + " ")]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }