var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "deal-list-content"
  }, _vm._l(_vm.pipelinesWithDeals, function (value, key) {
    return _c('div', {
      key: key,
      staticStyle: {
        "width": "100%"
      }
    }, [_vm.activeProjectsTab === 'projects' && key !== 'archived' || _vm.activeProjectsTab === 'archives' && key === 'archived' ? _c('div', {
      staticClass: "deal-pipeline"
    }, [_vm.activeProjectsTab !== 'archives' ? _c('v-row', {
      staticClass: "deal-header"
    }, [_c('div', {
      staticStyle: {
        "display": "flex",
        "width": "100%",
        "align-items": "center",
        "gap": "8px"
      },
      on: {
        "click": function ($event) {
          return _vm.setProjectListViewState(key);
        }
      }
    }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t(`ProjectPipeline.${key}`)) + " [" + _vm._s(value.count) + "] ")]), _c('img', {
      style: _vm.projectListViewStates[key] ? 'transform: rotate(180deg);' : 'transform: rotate(90deg);',
      attrs: {
        "src": require("../../assets/images/chevron.svg"),
        "alt": ""
      }
    })]), key === 'Done' && value.count > 0 ? _c('BaseButton', {
      staticStyle: {
        "margin-right": "12px",
        "max-height": "24px"
      },
      attrs: {
        "display-key": 'name',
        "display-value": 'value',
        "items": _vm.DoneDonePipelineDropdown,
        "size": 'icon',
        "icon-left": 'archive_button24_24.svg'
      },
      on: {
        "onConfirm": function ($event) {
          return _vm.archiveDeals(value);
        }
      }
    }) : _vm._e()], 1) : _vm._e(), _c('draggable', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.projectListViewStates[key],
        expression: "projectListViewStates[key]"
      }],
      key: _vm.taskUpdater,
      staticClass: "scrollable",
      attrs: {
        "list": value.deals,
        "animation": 200,
        "ghost-class": "ghost-card",
        "chosen-class": "chosen-card",
        "group": "tasks",
        "move": function (val) {
          return _vm.$emit('updatePipeline', val);
        },
        "id": 'drag-' + key
      }
    }, [_vm._l(value.deals, function (deal) {
      return _c('ListCard', {
        key: deal._id,
        staticClass: "cursor-move",
        staticStyle: {
          "margin-top": "12px"
        },
        attrs: {
          "deal": deal,
          "id": deal.pipelineStep
        },
        on: {
          "cardClick": function (dealId, event) {
            return _vm.$emit('cardClick', dealId, event);
          }
        }
      });
    }), value.loading ? _c('div', {
      staticStyle: {
        "width": "100%",
        "height": "120px",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center"
      }
    }, [_c('div', {
      staticClass: "loader"
    })]) : !value.allLoaded && !value.loading ? _c('div', {
      staticStyle: {
        "width": "100%",
        "height": "64px",
        "display": "flex",
        "align-items": "center",
        "justify-content": "center"
      }
    }, [_c('div', {
      staticClass: "link-small",
      on: {
        "click": function ($event) {
          return _vm.getMoreProjects(key);
        }
      }
    }, [_vm._v(_vm._s(_vm.$t('LoadMoreProjects')))])]) : _vm._e()], 2)], 1) : _vm._e()]);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }