<template>
  <v-menu
    v-model="status"
    :close-on-content-click="false"
    :nudge-width="205"
    offset-y
    bottom
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        v-if="listing"
        v-bind="attrs"
        v-on="on"
        @click="getOutputsUsers()"
        class="outputs-wrapper"
      >
        <div class="flex tags" style="gap: 0px">
          <div
            v-for="marketplace in clickedListing.marketplaces"
            :key="marketplace.name"
          >
            <div
              v-if="
                !listing.foreignCountry ||
                marketplace.name === 'www' ||
                user.isItaly
              "
              v-bind:class="{
                'tag gray': marketplace.isActive,
                tag: !marketplace.isActive,
              }"
              class="flex tag-wrapper"
              style="gap: 0px"
            >
              <div
                v-bind:class="{
                  active: marketplace.isActive,
                  'in-active': !marketplace.isActive,
                }"
              ></div>
              <p class="content-small">{{ marketplace.name }}</p>
            </div>
          </div>
        </div>
<!--        <div v-if="user.isItaly" class="flex tags" style="gap: 0px">-->
<!--          <div-->
<!--            v-for="marketplace in clickedListing.marketplaces"-->
<!--            :key="marketplace.name"-->
<!--          >-->
<!--            <div-->
<!--              v-bind:class="{-->
<!--                'tag gray': marketplace.isActive,-->
<!--                tag: !marketplace.isActive,-->
<!--              }"-->
<!--              class="flex tag-wrapper"-->
<!--              style="gap: 0px"-->
<!--            >-->
<!--              <div-->
<!--                v-bind:class="{-->
<!--                  active: marketplace.isActive,-->
<!--                  'in-active': !marketplace.isActive,-->
<!--                }"-->
<!--              ></div>-->
<!--              <p v-if="marketplace.name === 'kv'" class="content-small">casa</p>-->
<!--              <p v-if="marketplace.name === 'city24'" class="content-small">-->
<!--                idea-->
<!--              </p>-->
<!--              <p v-if="marketplace.name === 'kv24'" class="content-small">-->
<!--                immo-->
<!--              </p>-->
<!--              <p v-if="marketplace.name === 'www'" class="content-small">-->
<!--                {{ marketplace.name }}-->
<!--              </p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
      <div
        v-if="project"
        @click="clickedListing = listing"
        class="outputs-wrapper"
      >
        <div class="flex tags" style="gap: 0px">
          <div
            v-for="marketplace in clickedListing.marketplaces"
            :key="marketplace.name"
            v-bind:class="{
              'tag gray': marketplace.isActive,
              tag: !marketplace.isActive,
            }"
          >
            <div class="flex tag-wrapper" style="gap: 0px">
              <div
                v-bind:class="{
                  active: marketplace.isActive,
                  'in-active': !marketplace.isActive,
                }"
              ></div>
              <p class="content-small">{{ marketplace.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div
      id="outputs-modal"
      v-if="clickedListing"
      class="outputs-modal"
      :key="clickedListing._id"
    >
      <h5>{{ $t("Outputs") }}</h5>

      <v-row v-if="!listing.foreignCountry && !user.isItaly" class="output-row">
        <v-checkbox
          class="output-checkbox"
          v-model="selectedOutputs"
          label="kv"
          :disabled="user.companyId === 'S76LvflRF766oIt1NbvS'"
          value="kv"
        ></v-checkbox>
        <v-select
          outlined
          dense
          label="kv account"
          class="output-account"
          :items="brokerAccountsKv"
          v-model="account_kv_ee"
          :disabled="user.companyId === 'S76LvflRF766oIt1NbvS'"
          item-text="email"
          item-value="email"
          placeholder="Choose account"
        ></v-select>
      </v-row>
      <v-row v-if="!listing.foreignCountry && !user.isItaly" class="output-row">
        <v-checkbox
          class="output-checkbox"
          @change="checkEhakCode"
          v-model="selectedOutputs"
          label="city24"
          value="city24"
        ></v-checkbox>
        <v-select
          outlined
          dense
          label="city24 account"
          class="output-account"
          placeholder="Choose account"
          :items="brokerAccountsCity24"
          item-text="email"
          item-value="email"
          v-model="account_city24"
        ></v-select>
      </v-row>
      <v-row v-if="!listing.foreignCountry && !user.isItaly" class="output-row">
        <v-checkbox
            v-model="selectedOutputs"
            class="output-checkbox"
            label="kv24"
            value="kv24"
        ></v-checkbox>
        <v-select
            outlined
            dense
            label="kv24 account"
            class="output-account"
            :items="brokerAccountsKv24"
            v-model="account_kv24"
            item-text="email"
            item-value="email"
            placeholder="Choose account"
        ></v-select>
      </v-row>
      <v-row v-if="user.isItaly" class="output-row">
        <v-checkbox
            v-model="selectedOutputs"
            class="output-checkbox"
            label="casa"
            value="casa"
        ></v-checkbox>
        <v-select
            outlined
            dense
            label="casa account"
            class="output-account"
            :items="brokerAccountsCasa"
            v-model="account_casa"
            item-text="email"
            item-value="email"
            placeholder="Choose account"
        ></v-select>
      </v-row>
      <v-row v-if="user.isItaly" class="output-row">
        <v-checkbox
            v-model="selectedOutputs"
            class="output-checkbox"
            label="idea"
            value="idea"
        ></v-checkbox>
        <v-select
            outlined
            dense
            label="idea account"
            class="output-account"
            :items="brokerAccountsIdea"
            v-model="account_idea"
            item-text="email"
            item-value="email"
            placeholder="Choose account"
        ></v-select>
      </v-row>
      <v-row v-if="user.isItaly" class="output-row">
        <v-checkbox
            v-model="selectedOutputs"
            class="output-checkbox"
            label="immo"
            value="immo"
        ></v-checkbox>
        <v-select
            outlined
            dense
            label="immo account"
            class="output-account"
            :items="brokerAccountsImmo"
            v-model="account_immo"
            item-text="email"
            item-value="email"
            placeholder="Choose account"
        ></v-select>
      </v-row>
      <v-row class="output-row">
        <v-checkbox
            v-model="selectedOutputs"
            class="output-checkbox"
            label="www"
            value="www"
        ></v-checkbox>
        <v-select
            outlined
            dense
            label="www account"
            class="output-account"
            :items="brokerAccountsWww"
            v-model="account_www"
            item-text="email"
            item-value="email"
            placeholder="Choose account"
        ></v-select>
      </v-row>
      <button
        @click="
          saveOutputs();
          updateListingStatuses();
          status = false;
        "
        class="add-button"
      >
        {{ $t("Save") }}
      </button>
    </div>
  </v-menu>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "OutputsModal",
  props: ["listing", "project"],
  data() {
    return {
      status: false,
      clickedListing: null,
      selectedOutputs: [],
      brokerAccountsCity24: [],
      brokerAccountsKv24: [],
      brokerAccountsKv: [],
      brokerAccountsWww: [],
      brokerAccountsIdea: [],
      brokerAccountsCasa: [],
      brokerAccountsImmo: [],
      account_idea: [],
      account_immo: null,
      account_casa: null,
      account_kv24: null,
      account_city24: null,
      account_kv_ee: null,
      account_www: null,
      marketplaces: [
        {
          name: "kv",
          isActive: false,
        },
        {
          name: "city24",
          isActive: false,
        },
        {
          name: "kv24",
          isActive: false,
        },
        {
          name: "www",
          isActive: false,
        },
      ],
    };
  },
  created() {
    this.setupListing();
  },
  watch: {
    clickedListing: function () {
      this.selectedOutputs = [];
      //
      this.clickedListing.marketplaces.forEach((e) => {
        this.marketplaces.forEach((market) => {
          if (e.name === market.name && e.hasBeenPublished) {
            market.hasBeenPublished = true;
          }
        });

        if (e.isActive) {
          this.selectedOutputs.push(e.name);
        }
        if (e.name == "kv.ee" && e.activeUser) {
          this.account_kv_ee = e.activeUser;
          if (e.name == "kv24.ee" && e.activeUser) {
            this.account_kv24 = e.activeUser;
          }
          if (e.name == "city24.ee" && e.activeUser) {
            this.account_city24 = e.activeUser;
          }
          if (e.name == "www" && e.activeUser) {
            this.account_www = e.activeUser;
          }
        }
        if (e.name == "idea" && e.activeUser) {
          this.account_idea = e.activeUser;
        }
      });
    },
  },
  computed: {
    ...mapGetters(["user", "users"]),
    isEhakCodeValid() {
      return !(
        !this.clickedListing.ehakCode ||
        this.clickedListing.ehakCode === "" ||
        this.clickedListing.ehakCode === 0
      );
    },
  },
  methods: {
    async setupListing() {
      this.clickedListing = {
        _id: "outputListing",
        marketplaces: this.marketplaces,
      };

      if (this.listing) {
        this.clickedListing = this.listing;
      }

      if (this.project) {
        const response = await axios.get(
          `/api/project/listings/marketplaces/${this.project._id}`
        );

        response.data.forEach((activeMarketplace) => {
          this.marketplaces.forEach((marketplace) => {
            if (marketplace.name === activeMarketplace) {
              marketplace.isActive = true;
            }
          });
        });
        let isOpen = false;
        this.marketplaces.forEach((marketplace) => {
          if (marketplace.isActive) {
            isOpen = true;
          }
        });
        if (!isOpen) {
          this.$emit("show", false);
        }
        this.clickedListing = {
          _id: this.project._id,
          marketplaces: this.marketplaces,
        };
      }
    },
    async getOutputsUsers() {
      this.clickedListing = this.listing;
      this.listing.marketplaces.forEach((marketplace) => {
        if (
          marketplace.name === "kv24" &&
          marketplace.activeUser &&
          marketplace.isActive
        ) {
          this.account_kv24 = marketplace.activeUser;
        }
        if (
          marketplace.name === "kv" &&
          marketplace.activeUser &&
          marketplace.isActive
        ) {
          this.account_kv_ee = marketplace.activeUser;
        }
        if (
          marketplace.name === "city24" &&
          marketplace.activeUser &&
          marketplace.isActive
        ) {
          this.account_city24 = marketplace.activeUser;
        }
        if (
          marketplace.name === "www" &&
          marketplace.activeUser &&
          marketplace.isActive
        ) {
          this.account_www = marketplace.activeUser;
        }
      });
      //if (this.user.isAdmin) {
      if (this.users) {
        this.users.forEach((user) => {
          //if (user.email.includes("@runproperty.com")) return;
          if (user.email) {
            this.brokerAccountsKv.push({ email: user.email, platform: "kv" });
            this.brokerAccountsCity24.push({
              email: user.email,
              platform: "city24",
            });
            this.brokerAccountsKv24.push({
              email: user.email,
              platform: "kv24",
            });
            this.brokerAccountsWww.push({
              email: user.email,
              platform: "kv24",
            });
            this.brokerAccountsIdea.push({
              email: user.email,
              platform: "idea",
            });
            
          }
        });
      }

      this.brokerAccountsKv24.sort((a, b) =>
        a.email > b.email ? 1 : b.email > a.email ? -1 : 0
      );
      this.brokerAccountsKv.sort((a, b) =>
        a.email > b.email ? 1 : b.email > a.email ? -1 : 0
      );
      this.brokerAccountsCity24.sort((a, b) =>
        a.email > b.email ? 1 : b.email > a.email ? -1 : 0
      );
      this.brokerAccountsWww.sort((a, b) =>
        a.email > b.email ? 1 : b.email > a.email ? -1 : 0
      );
    },
    async saveOutputs() {
      if (!this.listing.foreignCountry) {
        await this.checkEhakCode();
      }
      if (!this.clickedListing.price && this.clickedListing.price !== 0) {
        this.toastError("Price is required");
        return;
      }

      let marketplaceNames = [];
      this.clickedListing.marketplaces.forEach((el) => {
        marketplaceNames.push(el.name);
      });

      this.selectedOutputs.forEach((e) => {
        let obj = this.clickedListing.marketplaces.find((o) => o.name === e);
        obj.isActive = true;
        obj.hasBeenPublished = true;
        if (obj.name == "kv" && this.account_kv_ee) {
          obj.activeUser = this.account_kv_ee;
        }
        if (obj.name == "city24" && this.account_city24) {
          obj.activeUser = this.account_city24;
        }
        if (obj.name == "kv24" && this.account_kv24) {
          obj.activeUser = this.account_kv24;
        }
        if (obj.name == "www") {
          obj.activeUser = this.account_www;
          this.createCompanyListingsLanderLink();
        }
        if (obj.name == "idea") {
          obj.activeUser = this.account_idea;
        }
      });

      let difference = marketplaceNames.filter(
        (x) => this.selectedOutputs.indexOf(x) === -1
      );

      difference.forEach((ele) => {
        let object = this.clickedListing.marketplaces.find(
          (o) => o.name === ele
        );
        object.isActive = false;
      });

      for (const marketplace of this.clickedListing.marketplaces) {
        if (
          marketplace.activeUser &&
          marketplace.activeUser !== this.clickedListing.creator.email
        ) {
          const user = this.users.find(
            (u) => u.email === marketplace.activeUser
          );
          if (
            user &&
            !this.clickedListing.sharedCreators.some(
              (c) => c.userId === user._id
            )
          ) {
            this.clickedListing.sharedCreators.push({
              userId: user._id,
              name: user.name,
              ownershipType: "CanEdit",
            });
          }
        }
      }

      let dataObject = {
        listing: this.clickedListing,
        userId: this.user._id,
        companyId: this.user.companyId,
      };

      console.log("listing", this.clickedListing)

      await this.validateLimits();
      console.log("jalg")

      await axios.patch("/api/listing", this.clickedListing);
      await axios.patch("/api/listing/update/xml", dataObject);
    },
    async createCompanyListingsLanderLink() {
      let data = { companyId: this.user.companyId };
      await axios.post("/api/listing/company-lander-link", data);
    },
    async updateListingStatuses() {
      if (!this.listing.foreignCountry) {
        await this.checkEhakCode();
      }
      this.$emit("updateStatuses", {
        listingId: this.clickedListing._id,
        marketplaces: this.clickedListing.marketplaces,
      });
    },
    async validateLimits() {
      const limitValidation = await axios.post(
        `/api/listing/validate-limits`,
        this.clickedListing
      );

      const overLimitOutputs = limitValidation.data.overLimit;
      if (overLimitOutputs.length > 0) {
        for (const overLimitOutput of overLimitOutputs) {
          this.clickedListing.marketplaces.forEach((output) => {
            if (output.name === overLimitOutput) {
              output.isActive = false;
            }
          });
          this.toastError(
            `${overLimitOutput.toUpperCase()} ` + this.$t("LimitHasBeenReached")
          );
        }
      }
    },
    async checkEhakCode(e) {
      if (
        (e && e.includes("city24")) ||
        this.selectedOutputs.includes("city24")
      ) {
        if (!this.isEhakCodeValid) {
          const response = await axios.get(
            `http://inaadress.maaamet.ee/inaadress/gazetteer?address=${this.clickedListing.cadastralNumber}`,
            {
              withCredentials: false,
            }
          );
          if (
            response.status === 200 &&
            (response.data.addresses[0].ehak ||
              response.data.addresses[0].ehakov)
          ) {
            if (!response.data.addresses[0].ehak) {
              this.clickedListing.ehakCode = await response.data.addresses[0]
                .ehakov;
            } else {
              this.clickedListing.ehakCode = await response.data.addresses[0]
                .ehak;
            }

            if (!this.user.config || (this.user.config && this.user.access?.features?.modules?.projects?.enabled)) {
              const data = {
                linkedDeals: this.clickedListing.linkedDeals,
                ehakCode: this.clickedListing.ehakCode,
              };
              await axios.post(`/api/project/update-ehak-code`, data);
            }
          } else {
            this.selectedOutputs = this.selectedOutputs.filter(
              (output) => output !== "city24"
            );
            this.clickedListing.marketplaces =
              this.clickedListing.marketplaces.map((marketplace) => {
                if (marketplace.name === "city24") {
                  return { ...marketplace, isActive: false };
                }
                return marketplace;
              });
            this.toastError(this.$t("CannotExportWithoutEhakCode"));
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tag {
  height: 24px;
  padding: 4px 8px;
  margin-right: 4px;
  border-radius: 4px;
  background-color: #f4f5f7;
}

.tags {
  p {
    margin-bottom: 0 !important;
    font-size: 12px;
    line-height: 16px;
  }
  .active {
    width: 8px;
    height: 8px;
    margin-right: 8px;
    background-image: url("../../assets/images/green-circle.svg");
  }
  .tag-wrapper {
    align-items: center;
  }
}

.in-active {
  width: 8px;
  height: 8px;
  margin-right: 8px;
  background-image: url("../../assets/images/red-circle.svg");
}

.outputs-modal {
  background: white;
  padding: 16px;
}

.output-row {
  align-items: center;
  gap: 16px;
  flex-wrap: nowrap;
}

.output-checkbox {
  width: 100px;
}

.output-account {
  width: 200px;
}
</style>
