var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.deal ? _c('div', {
    staticClass: "overlay"
  }, [_c('div', {
    staticClass: "panel-wrapper",
    attrs: {
      "id": "panel-wrapper-deal"
    }
  }, [!_vm.deal ? _c('div', {
    staticClass: "overlay"
  }, [_vm._m(0)]) : _vm._e(), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.editModalOpen,
      expression: "!editModalOpen"
    }]
  }, [_c('v-col', {
    staticClass: "customer-row"
  }, [_c('v-row', {
    staticStyle: {
      "justify-content": "space-between"
    }
  }, [_vm.deal.name ? _c('h4', {
    staticStyle: {
      "align-self": "center"
    }
  }, [_vm._v(_vm._s(_vm.deal.name))]) : _vm._e(), _c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "8px",
      "justify-content": "flex-end"
    }
  }, [_c('div', {
    staticClass: "edit-button",
    on: {
      "click": function ($event) {
        return _vm.openSidepanelAdd(_vm.deal);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/edit.svg"),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "edit-button-text"
  }, [_vm._v("Edit")])])])]), _c('v-row', {
    staticStyle: {
      "gap": "18px",
      "align-items": "center",
      "height": "30px"
    }
  }, [_vm.deal.amount != '' && _vm.deal.amount != null ? _c('p', {
    staticClass: "edit-button-text"
  }, [_vm._v(" " + _vm._s(_vm.deal.amount) + " € ")]) : _vm._e(), _c('div', {
    staticClass: "vertical-line"
  }), _c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "8px"
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/user.svg"),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "edit-button-text"
  }, [_vm._v(" " + _vm._s(_vm.deal.contactPerson.customerName) + " ")])]), _c('div', {
    staticClass: "vertical-line"
  }), _c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "8px",
      "align-items": "center"
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/building16x16.svg"),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "edit-button-text"
  }, [_vm._v(" " + _vm._s(_vm.deal.objectName) + " ")])]), _c('div', {
    staticClass: "vertical-line"
  }), _c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "8px",
      "align-items": "center"
    }
  }, [_c('p', {
    staticClass: "edit-button-text"
  }, [_vm._v(" " + _vm._s(_vm.deal.dealType) + " ")])])])], 1), _c('div', {
    staticClass: "main-wrapper"
  }, [_c('div', {
    staticClass: "sub-block"
  }, [_c('v-col', {
    staticClass: "sub-heading"
  }, [_c('h6', [_vm._v(" " + _vm._s(_vm.$t("Description")) + " ")])]), _c('div', {
    staticClass: "description"
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.deal.description,
      expression: "deal.description"
    }],
    staticClass: "description-item",
    attrs: {
      "outlined": "",
      "rows": "5",
      "placeholder": _vm.$t('Description'),
      "disabled": !_vm.editMode
    },
    domProps: {
      "value": _vm.deal.description
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.deal, "description", $event.target.value);
      }
    }
  })])], 1), _c('div', {
    staticClass: "sub-block"
  }, [_c('h6', {
    staticClass: "sub-heading"
  }, [_vm._v(" " + _vm._s(_vm.$t("Notes")) + " ")]), _c('div', {
    staticClass: "notes"
  }, [_c('Notes', {
    attrs: {
      "notes": _vm.notes,
      "newNote": _vm.newNote
    },
    on: {
      "newNote": function ($event) {
        return _vm.addNewNote($event);
      },
      "saveNotesChild": function ($event) {
        return _vm.saveNotes($event);
      }
    }
  })], 1)]), _c('div', {
    staticClass: "sub-block"
  }, [_c('h6', {
    staticClass: "sub-heading"
  }, [_vm._v(" " + _vm._s(_vm.$t("Tasks")) + " ")]), _c('div', {
    staticClass: "activities"
  }, [_c('Tasks', {
    attrs: {
      "tasks": _vm.tasks,
      "newTask": _vm.newTask
    },
    on: {
      "newTask": function ($event) {
        return _vm.addNewTask($event);
      },
      "saveTasksChild": function ($event) {
        return _vm.saveTasks($event);
      }
    }
  })], 1)]), _c('div', {
    staticClass: "sub-block"
  }, [_c('h6', {
    staticClass: "sub-heading"
  }, [_vm._v(" " + _vm._s(_vm.$t("ContactPerson2")) + " ")]), _vm.editMode ? _c('v-autocomplete', {
    staticClass: "no-active required",
    attrs: {
      "outlined": "",
      "prepend-inner-icon": "mdi-magnify",
      "dense": "",
      "items": _vm.customers,
      "autocomplete": "nope",
      "item-text": "customerName",
      "return-object": "",
      "label": _vm.$t('ContactPerson')
    },
    scopedSlots: _vm._u([{
      key: "prepend-inner",
      fn: function () {
        return [_c('img', {
          attrs: {
            "width": "20",
            "height": "20",
            "src": require("../../assets/images/user.svg")
          }
        })];
      },
      proxy: true
    }], null, false, 2166859921),
    model: {
      value: _vm.deal.contactPerson,
      callback: function ($$v) {
        _vm.$set(_vm.deal, "contactPerson", $$v);
      },
      expression: "deal.contactPerson"
    }
  }) : _vm._e(), !_vm.editMode ? _c('p', {
    staticClass: "value description contactperson",
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          name: 'KliendiregisterDetail',
          params: {
            id: _vm.deal.contactPerson.customerId
          }
        });
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.deal.contactPerson.customerName) + " ")]) : _vm._e()], 1), _c('div', {
    staticClass: "sub-block"
  }, [_c('h6', {
    staticClass: "sub-heading"
  }, [_vm._v(" " + _vm._s(_vm.$t("RelatedListings")) + " ")]), _c('div', {
    staticClass: "related-content"
  }, [_c('v-row', {
    staticClass: "object-row object",
    staticStyle: {
      "align-items": "end"
    }
  }, [_c('img', {
    staticClass: "filtered",
    attrs: {
      "src": require("../../assets/images/building.svg"),
      "alt": ""
    }
  }), !_vm.deal.objectName ? _c('p', {
    staticClass: "object-link",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.$router.push({
          name: 'ListingsAdd',
          params: {
            id: _vm.deal._id
          }
        });
      }
    }
  }, [_vm._v(" Lisa kuulutus ")]) : _vm._e(), _vm.deal.objectName ? _c('p', {
    staticClass: "object-link",
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          path: `/listings/listing/${_vm.deal.object._id}`
        });
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.deal.objectName) + " ")]) : _vm._e()])], 1)]), _c('FileUpload', {
    attrs: {
      "files": _vm.deal.files,
      "targetId": _vm.deal._id,
      "target-object": 'project'
    },
    on: {
      "success": _vm.getDeal,
      "update:files": function ($event) {
        return _vm.$set(_vm.deal, "files", $event);
      }
    }
  })], 1)], 1)]), _vm.editModalOpen ? _c('SidepanelAdd', {
    attrs: {
      "dealData": _vm.deal,
      "isEdit": true
    },
    on: {
      "closed": function ($event) {
        return _vm.closeSidepanelAdd();
      }
    }
  }) : _vm._e()], 1) : _vm._e();

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlay__inner"
  }, [_c('div', {
    staticClass: "overlay__content"
  }, [_c('span', {
    staticClass: "spinner"
  })])]);

}]

export { render, staticRenderFns }