var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Header', {
    attrs: {
      "initFilterCondition": _vm.initFilterCondition,
      "project-settings-panel-status": _vm.projectSettingsPanelStatus
    },
    on: {
      "openProjectSettings": _vm.openProjectSettings
    }
  }), _vm.projectSettingsOpened ? _c('div', {
    staticClass: "project-settings-header",
    attrs: {
      "id": "project-settings-header"
    }
  }, [_c('div', {
    staticStyle: {
      "margin-right": "32px"
    }
  }, [_c('p', {
    staticClass: "content-small-semibold",
    staticStyle: {
      "color": "white"
    }
  }, [_vm._v(" " + _vm._s(this.$t("ConfigureProjectTile")) + " ")])]), _c('div', {
    staticClass: "toggles",
    staticStyle: {
      "display": "flex"
    }
  }, [_c('BaseToggle', {
    attrs: {
      "label": _vm.$t('ProjectName'),
      "labelColour": 'white'
    },
    on: {
      "click": function ($event) {
        return _vm.changeProjectSettings($event, 'projectName');
      }
    },
    model: {
      value: _vm.user.preferences.projects.projectName.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.user.preferences.projects.projectName, "enabled", $$v);
      },
      expression: "user.preferences.projects.projectName.enabled"
    }
  }), _vm.user.preferences.projects.projectName.enabled ? _c('div', {
    staticClass: "vertical-separator"
  }) : _vm._e(), _vm.user.preferences.projects.projectName.enabled ? _c('BaseToggle', {
    attrs: {
      "label": _vm.$t('ShortAddress'),
      "labelColour": 'white'
    },
    on: {
      "click": function ($event) {
        return _vm.changeProjectSettings($event, 'projectName.shortAddress');
      }
    },
    model: {
      value: _vm.user.preferences.projects.projectName.shortAddress.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.user.preferences.projects.projectName.shortAddress, "enabled", $$v);
      },
      expression: "user.preferences.projects.projectName.shortAddress.enabled"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "vertical-separator"
  }), _c('BaseToggle', {
    attrs: {
      "label": _vm.$t('Contact'),
      "labelColour": 'white'
    },
    on: {
      "click": function ($event) {
        return _vm.changeProjectSettings($event, 'clientName');
      }
    },
    model: {
      value: _vm.user.preferences.projects.clientName.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.user.preferences.projects.clientName, "enabled", $$v);
      },
      expression: "user.preferences.projects.clientName.enabled"
    }
  }), _c('div', {
    staticClass: "vertical-separator"
  }), _c('BaseToggle', {
    attrs: {
      "label": _vm.$t('BrokerageFee'),
      "labelColour": 'white'
    },
    on: {
      "click": function ($event) {
        return _vm.changeProjectSettings($event, 'brokerageFee');
      }
    },
    model: {
      value: _vm.user.preferences.projects.brokerageFee.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.user.preferences.projects.brokerageFee, "enabled", $$v);
      },
      expression: "user.preferences.projects.brokerageFee.enabled"
    }
  }), _c('div', {
    staticClass: "vertical-separator"
  }), _c('BaseToggle', {
    attrs: {
      "label": _vm.$t('ProjectValue'),
      "labelColour": 'white'
    },
    on: {
      "click": function ($event) {
        return _vm.changeProjectSettings($event, 'projectValue');
      }
    },
    model: {
      value: _vm.user.preferences.projects.projectValue.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.user.preferences.projects.projectValue, "enabled", $$v);
      },
      expression: "user.preferences.projects.projectValue.enabled"
    }
  }), _c('div', {
    staticClass: "vertical-separator"
  }), _c('BaseToggle', {
    attrs: {
      "label": _vm.$t('AdditionalInfo'),
      "labelColour": 'white'
    },
    on: {
      "click": function ($event) {
        return _vm.changeProjectSettings($event, 'additionalInformation');
      }
    },
    model: {
      value: _vm.user.preferences.projects.additionalInformation.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.user.preferences.projects.additionalInformation, "enabled", $$v);
      },
      expression: "user.preferences.projects.additionalInformation.enabled"
    }
  })], 1), _c('div', {
    staticClass: "vertical-separator"
  }), _c('div', {
    staticClass: "close-button",
    on: {
      "click": function ($event) {
        return _vm.closeProjectSettingsPanel();
      }
    }
  }, [_c('img', {
    staticClass: "white-image",
    attrs: {
      "src": require("../../assets/images/close.svg"),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "button-small",
    staticStyle: {
      "color": "white"
    }
  }, [_vm._v(_vm._s(this.$t("Close")))])])]) : _vm._e(), _c('Content', {
    attrs: {
      "pipelinesWithUsableDeals": _vm.projects
    },
    on: {
      "reloadKanban": _vm.getProjectsForPipelineByFilters,
      "getProjectsForPipeline": _vm.getProjectsForPipelineByFilters
    }
  }), _vm.sidepanelDeal ? _c('SidepanelDetail') : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }