<template>
  <a
    :href="path + '/' + deal._id + '/general'"
    class="deal-grid-card-content"
    style="display: inherit; text-decoration: unset; position: relative"
    :class="cardBorderColor"
    @click="$emit('cardClick', deal._id, $event)"
  >
    <v-row
      style="display: flex; margin-bottom: 4px; justify-content: space-between"
    >
    <DealTypeContainer :deal-type="deal.dealType"/>
     <!--  <v-row
        v-bind:class="{
          'green-color': deal.dealType === 'Sale',
          'dark-blue-color': deal.dealType === 'Purchase',
          'pink-color': deal.dealType === 'LeaseOut',
          'orange-color': deal.dealType === 'AquireLease',
          'brown-color': deal.dealType === 'Referral',
        }"
        style="
          max-width: 24px;
          height: 4px;
          border-radius: 100px;
          margin-top: 2px;
        "
      ></v-row> -->
      <div
        class="price-section"
        style="display: flex; justify-content: flex-end"
      >
        <span
          style="height: 16px"
          v-if="
            deal.brokerageFee &&
            user.preferences &&
            user.preferences.projects.brokerageFee.enabled
          "
          v-bind:class="{
            'deal-amount-con content-small': deal.pipelineStep !== 'Done',
            'deal-amount-con content-small green-color white-text':
              deal.pipelineStep === 'Done' &&
              deal.endState[deal.endState.length - 1].projectEndReason ===
                'Won',
            'deal-amount-con content-small red-color white-text-line-through':
              deal.pipelineStep === 'Done' &&
              (deal.endState[deal.endState.length - 1].projectEndReason ===
                'Lost' ||
                deal.endState[deal.endState.length - 1].projectEndReason ===
                  'Cancelled'),
          }"
        >
          {{ formatAmount(deal.brokerageFee) }}
        </span>
        <span
          v-if="
            deal.amount &&
            user.preferences &&
            user.preferences.projects.projectValue.enabled
          "
          class="deal-amount-con content-small"
          style="color: #75787a; height: 16px"
        >
          {{ formatAmount(deal.amount) }}
        </span>
      </div>
    </v-row>

    <v-row
      v-if="
        user.preferences &&
        user.preferences.projects.projectName.enabled &&
        !user.preferences.projects.projectName.shortAddress.enabled
      "
      class="content-semibold"
      style="margin-top: 4px"
    >
      {{ deal.name }}
    </v-row>
    <v-row
      v-if="
        user.preferences &&
        user.preferences.projects.projectName.enabled &&
        user.preferences.projects.projectName.shortAddress.enabled
      "
      class="content-semibold"
      style="margin-top: 4px"
    >
      {{ deal.name.split(",")[0] }}
    </v-row>
    <v-row
      v-if="user.preferences && user.preferences.projects.clientName.enabled"
      class="content-small"
      style="margin-top: 4px; font-weight: 400"
    >
      {{ deal.contactPerson.contactName }}
    </v-row>
    <v-row
      v-if="
        (user.preferences &&
          user.preferences.projects.additionalInformation.enabled) ||
        deal.pipelineStep === 'Done'
      "
      :class="cardSeparatorColor"
      style="margin-bottom: 8px; margin-top: 8px"
    ></v-row>

    <v-row
      class="deal-tasks-files-con"
      style="margin-top: 8px"
      v-if="
        deal.pipelineStep !== 'Done' &&
        user.preferences &&
        user.preferences.projects.additionalInformation.enabled
      "
    >
      <OpenHouseContainer
        v-if="deal.openHouse"
        :task="deal.openHouse"
        :variant="'small'"
      />

      <div style="display: flex; gap: 8px">
        <span
          style="background: #f4f5f7; border-radius: 1006px; padding: 2px"
          class="tasks-con"
        >
          <img
            class="task-icon"
            src="../../assets/images/calendar.svg"
            alt=""
          />
          <span>
            {{ this.unfinishedEventsCounter }}
          </span>
        </span>
        <span
          style="background: #f4f5f7; border-radius: 1006px; padding: 2px"
          class="tasks-con"
        >
          <img class="task-icon" src="../../assets/images/task.svg" alt="" />
          <span>
            {{ this.unfinishedCounter }}
          </span>
        </span>
        <span
          style="background: #f4f5f7; border-radius: 1006px; padding: 2px"
          class="tasks-con"
        >
          <img
            class="task-icon"
            src="../../assets/images/sticky-note.svg"
            alt=""
          />
          <span>
            {{ deal.notes.filter((note) => note.statusCode !== 0).length }}
          </span>
        </span>

        <span
          style="background: #f4f5f7; border-radius: 1006px; padding: 2px"
          class="files-con"
        >
          <img class="task-icon" src="../../assets/images/attach.svg" alt="" />
          <span>
            {{ deal.files.length }}
          </span>
        </span>
      </div>
    </v-row>
    <v-row class="deal-completed-con" v-if="deal.pipelineStep === 'Done'">
      <span
        class="content-small-semibold"
        :class="dealWon ? 'deal-won' : 'deal-lost'"
      >
        {{ projectEndReason }}
      </span>
      <span
        class="content-small-semibold"
        :class="dealWon ? 'deal-won' : 'deal-lost'"
      >
        {{ deal.doneDate }}
      </span>
    </v-row>
    <div class="open-tab-button-container">
    <div class="open-new-tab-button" @click.stop.prevent="openProjectNewTab(deal)">
      <img
        style="width: 16px; height: 16px"
        src="../../assets/images/open_new_tab.svg"
        alt=""
      />
    </div>
    </div>
  </a>
</template>
<script>
import DealTypeContainer from "@/components/common/DealTypeContainer";
import OpenHouseContainer from "@/components/common/OpenHouseContainer";
import projectApi from "@/http/project";
import { mapGetters } from "vuex";

export default {
  components: {
    DealTypeContainer,
    OpenHouseContainer,
  },
  props: {
    deal: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    unfinishedCounter: 0,
    unfinishedEventsCounter: 0,
    dealWon: false,
    path: null,
  }),
  created() {
    if (
      this.user.preferences &&
      this.user.preferences.projects.additionalInformation.enabled
    ) {
      this.unfinishedTasks(this.deal);
      this.unFinishedEventsAndOpenHouses(this.deal);
    }
    if (
      this.deal.endState &&
      this.deal.endState[this.deal.endState.length - 1].projectEndReason ===
        "Won"
    ) {
      this.dealWon = true;
    }
    this.path = document.URL;
  },
  mounted() {},
  computed: {
    ...mapGetters(["user"]),
    cardBorderColor() {
      if (
        this.deal.pipelineStep === "Done" ||
        this.deal.pipelineStep === "archived"
      ) {
        return this.dealWon ? "deal-card-win-border" : "deal-card-lost-border";
      } else if (this.deal.dealType === "Sale") {
        return "deal-card-green-hover";
      } else if (this.deal.dealType === "LeaseOut") {
        return "deal-card-pink-hover";
      } else if (this.deal.dealType === "Purchase") {
        return "deal-card-dark-blue-hover";
      } else if (this.deal.dealType === "AquireLease") {
        return "deal-card-orange-hover";
      } else if (this.deal.dealType === "Referral") {
        return "deal-card-brown-hover";
      }
      return null;
    },
    cardSeparatorColor() {
      if (
        this.deal.pipelineStep === "Done" ||
        this.deal.pipelineStep === "archived"
      ) {
        return this.dealWon
          ? "deal-card-win-separator"
          : "deal-card-lost-separator";
      } else {
        return "deal-card-regular-separator";
      }
    },
    projectEndReason() {
      if (
        this.deal.endState &&
        this.deal.endState[this.deal.endState.length - 1].projectEndReason &&
        this.$te(
          this.deal.endState[this.deal.endState.length - 1].projectEndReason
        )
      ) {
        return this.$t(
          this.deal.endState[this.deal.endState.length - 1].projectEndReason
        );
      }
      return this.$t("Lost");
    },
  },
  watch: {
    user: {
      handler() {
        if (
          this.user.preferences &&
          this.user.preferences.projects.additionalInformation.enabled
        ) {
          this.unfinishedTasks(this.deal);
          this.unFinishedEventsAndOpenHouses(this.deal);
        }
      },
      deep: true,
    },
  },
  methods: {
    async unfinishedTasks(e) {
      this.unfinishedCounter = await projectApi.getUnfinishedTasksCount(e._id);
    },

    async unFinishedEventsAndOpenHouses(e) {
      this.unfinishedEventsCounter = await projectApi.getUnfinishedEventsCount(
        e._id
      );
    },
    openProjectNewTab(project) {
      window.open(this.path + '/' + project._id + '/general', "_blank")
    },
    formatAmount(value) {
      const amount = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      let moneySign = "";
      switch (this.deal.currency) {
        case "EUR":
          moneySign = "€";
          break;
        case "USD":
          moneySign = "$";
          break;
      }

      return amount + " " + moneySign;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/css/DealCard";

.deal-tasks-files-con {
  flex-direction: row;
  min-width: 100%;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.deal-grid-card-content {
  background-color: white;
  border-radius: 4px;
  padding: 12px;
  cursor: pointer;
  min-width: 208px;
  max-width: 286px;
  border: 1px solid #e6e8ec;
  &:hover .open-tab-button-container {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 11px;
    right: 11px;
  }
}
.deal-card-green-hover {
  &:hover {
    border: 2px solid #0aaf60;
    padding: 11px;
  }
}
.deal-card-pink-hover {
  &:hover {
    border: 2px solid #f81ca0;
    padding: 11px;
  }
}
.deal-card-dark-blue-hover {
  &:hover {
    border: 2px solid #0151af;
    padding: 11px;
  }
}
.deal-card-orange-hover {
  &:hover {
    border: 2px solid #ff8a00;
    padding: 11px;
  }
}
.deal-card-brown-hover {
  &:hover {
    border: 2px solid #b54616;
    padding: 11px;
  }
}
.green-color {
  background: #0aaf60;
}
.white-text {
  color: white;
  border: 1px solid #0aaf60;
}
.red-color {
  background: #ff1e24;
}
.white-text-line-through {
  color: white;
  text-decoration-line: line-through;
  border: 1px solid #ff1e24;
}
.dark-blue-color {
  background: #0151af;
}
.pink-color {
  background: #f81ca0;
}
.orange-color {
  background: #ff8a00;
}
.brown-color {
  background: #b54616;
}
.content-small {
  font-weight: 600;
}
.open-new-tab-button {
  width: 20px;
  height: 20px;
  padding: 2px;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: #000000;
    img {
      filter: invert(100%) sepia(22%) saturate(0%) hue-rotate(78deg)
        brightness(109%) contrast(101%);
    }
  }
}
.open-tab-button-container {
  border-radius: 4px;
  display: none;
  background: white;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 1px 2px 0px rgba(0, 0, 0, 0.03), 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
}
</style>
