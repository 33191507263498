<template>
  <vue-draggable-resizable
    :z="3"
    style="position: fixed"
    :resizable="false"
    drag-handle=".drag-area"
    id="widget-overlay"
    class="widget-overlay"
    @dragstop="onDragStop"
  >
    <div id="widget" class="content" v-click-outside="closePanel">
      <div class="drag-area">
        <img src="../../assets/images/menu.svg" alt="" />
      </div>
      <div class="filter-panel-content">
        <div>
          <input
            v-if="filter.name == 'allValues'"
            class="filter-title"
            :placeholder="$t(filter.filterTitle)"
            :disabled="true"
          />
          <input
            v-else
            class="filter-title"
            v-model="filter.filterTitle"
            :placeholder="$t('AddTitle')"
            :disabled="filter.name === 'allValues'"
          />
        </div>

        <div v-if="showErrors && !filter.filterTitle" class="error-text">
          {{ $t("FormErrors.FieldRequired") }}
        </div>
        <div v-if="showErrors && filter.filterTitle" class="error-text">
          {{ $t("FormErrors.FilterNameExists") }}
        </div>

        <div class="additional-parameters">
          <div
            class="default-view"
            @click="clickToggle"
            :class="{
              'default-view-disabled': isToggleDisabled,
            }"
          >
            <img
              :src="
                filter.isDefault
                  ? require('../../assets/images/toggle-active-24x24.svg')
                  : require('../../assets/images/toggle-inactive.svg')
              "
              alt=""
              class="img24"
              :class="{
                'toggle-disabled': isToggleDisabled,
              }"
            />

            <div>
              {{ $t("SelectedByDefaultInTheView") }}
            </div>
          </div>
          <div
            v-if="editFilter.name !== 'allValues' && editFilter._id !== '1'"
            class="default-view"
            @click="toggleOverwrite"
          >
            <img
              :src="
                isOverwrite
                  ? require('../../assets/images/toggle-active-24x24.svg')
                  : require('../../assets/images/toggle-inactive.svg')
              "
              alt=""
              class="img24"
            />

            <div>
              {{ $t("Overwrite") }}
            </div>
          </div>
        </div>
      </div>
      <div class="filter-panel-footer">
        <span style="display: flex; gap: 8px">
          <button class="transparent-button" @click="closePanel">
            {{ $t("Cancel") }}
          </button>
          <button
            class="transparent-button"
            name="generate-doc-button"
            style="background: black !important; color: white"
            @click="saveFilter"
          >
            <span>
              {{ $t("Save") }}
            </span>
          </button>
        </span>
      </div>
    </div>
  </vue-draggable-resizable>
</template>

<script>
import "vue-date-pick/dist/vueDatePick.css";
import { mapActions, mapGetters } from "vuex";
import VueDraggableResizable from "vue-draggable-resizable";
import nanoid from "nanoid";
import _ from "lodash";
export default {
  name: "FilterPanel",
  components: {
    VueDraggableResizable,
  },
  props: ["filterName", "editFilter", "edit"],
  data() {
    return {
      isOverwrite: true,
      showErrors: false,
      temp: "",
      posX: 0,
      posY: 0,
      filter: {
        filterTitle: "",
        isDefault: false,
        name: "",
        conditions: [],
        items: [],
        activeTab: "",
      },
      isFilterExist: false,
    };
  },
  created() {
    this.initFilter();
  },
  mounted() {},
  watch: {},
  computed: {
    ...mapGetters(["filters", "provisionalFilters", "activeContactsTab"]),
    isToggleDisabled() {
      return this.filter.name === "allValues" && this.filter.isDefault;
    },
  },
  methods: {
    ...mapActions([
      "addFilter",
      "updateFilterByFilterName",
      "removeFilterById",
    ]),
    toggleOverwrite() {
      this.isOverwrite = !this.isOverwrite;
    },
    multipleChosenValues(condition) {
      let hasMultipleValues = false;

      if (condition.value instanceof Array && condition.value.length > 0) {
        hasMultipleValues = true;
      } else {
        hasMultipleValues = false;
      }
      return hasMultipleValues;
    },
    correctCase(value) {
      let correctedValue = value;

      correctedValue = value.toLowerCase();
      correctedValue = correctedValue.replaceAll("_", " ");
      correctedValue = correctedValue.split(" ");
      correctedValue = this.capitalizeWords(correctedValue);
      correctedValue = correctedValue.join("");

      return correctedValue;
    },
    capitalizeWords(arr) {
      return arr.map((element) => {
        return (
          element.charAt(0).toUpperCase() + element.substring(1).toLowerCase()
        );
      });
    },
    initFilter() {
      if (this.edit) {
        this.filter = _.cloneDeep(this.editFilter);
        return;
      }
      this.provisionalFilters[this.filterName].forEach((filter) => {
        const condition = {
          name: filter.name,
          title: filter.title,
          value: filter.value,
          valueTitle: filter.valueTitle,
        };
        this.filter.conditions.push(condition);
      });
    },
    getValueTitle(title) {
      if (!title) {
        return "-";
      } else if (title.includes("all")) {
        return "All";
      } else if (title.length > 0) {
        const tempTitle = title[0].toUpperCase() + title.slice(1);
        if (this.$te(tempTitle)) {
          return tempTitle;
        }
      }
      return title;
    },
    clickToggle() {
      if (this.filter.name === "allValues" && this.filter.isDefault) return;
      this.filter.isDefault = !this.filter.isDefault;
    },
    async saveFilter() {
      this.isFilterExist = false;
      if (!this.filter.filterTitle) {
        this.showErrors = true;
        return;
      }
      this.isFilterExist = this.filters.listings.some((filter) => {
        if (filter.filterTitle === this.filter.filterTitle) {
          return true;
        }
      });
      if (this.isFilterExist) {
        this.showErrors = true;
        return;
      }
      this.filter.conditions = this.provisionalFilters[this.filterName];

      if (this.filterName === 'contacts') {
        this.filter.activeTab = this.activeContactsTab
      }

      if (this.filter._id && this.isOverwrite) {
        await this.updateFilterByFilterName({
          filterName: this.filterName,
          filter: this.filter,
        });
      } else {
        this.filter._id = nanoid(8);

        await this.addFilter({
          filterName: this.filterName,
          filter: this.filter,
        });
      }
      this.closePanel();
    },
    closePanel() {
      this.$emit("closePanel");
    },
    onDragStop(left, top) {
      this.posX = left;
      this.posY = top;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.default-view-disabled {
  cursor: unset !important;
}

.toggle-disabled {
  filter: invert(90%) sepia(13%) saturate(5%) hue-rotate(332deg) brightness(89%)
    contrast(92%);
}

.disabled-filter-container {
  border: 1px solid $grey800 !important;
  color: $grey800 !important;
  cursor: auto !important;
}

.additional-parameters {
  display: flex;
  flex-direction: row;
  padding-top: 32px;
  gap: 32px;
}

.default-view {
  display: flex;
  gap: 12px;
  align-items: center;
  inline-size: fit-content;
  cursor: pointer;
}

.filter-container {
  display: flex;
  gap: 4px;
  padding: 6px 8px;
  border: 1px solid $orange300;
  border-radius: 4px;
  max-width: fit-content;
  cursor: pointer;
}

.selected-conditions {
  padding-left: 36px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.selected-conditions-label {
  display: flex;
  gap: 12px;
  padding-top: 32px;
  align-items: start;
  div {
    color: $grey950;
  }
}

.filter-title {
  border-bottom: 2px solid black;
  width: 100%;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  padding: 4px 4px 8px 4px;
}

input {
  border: 0;
  outline: 0;
}
input:focus {
  outline: none !important;
}

.delete-button {
  color: $red !important;
  border: 1px solid $red !important;
}

.filter-panel-content {
  padding: 24px;
}

.filter-panel-footer {
  padding: 24px;
  border-top: 1px solid $grey500;
  display: flex;
  justify-content: flex-end;
}

.widget-overlay {
  z-index: 130;
  position: fixed;
  display: flex;
}

#widget {
  top: 150%;
  left: 150%;
  position: fixed;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.03),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  width: 640px;
}

.drag-area {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  background-color: $grey;
  padding: 12px 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 1px solid $grey500;
  img {
    cursor: pointer;
  }
}
</style>
