var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    key: _vm.filtersUpdater,
    staticClass: "header",
    staticStyle: {
      "background-color": "white",
      "padding-top": "0px"
    }
  }, [_c('v-row', {
    staticClass: "title-row"
  }, [_c('h3', {
    staticClass: "header-title"
  }, [_vm._v(_vm._s(_vm.$t("Projects")))]), _c('AddNewButton')], 1), _c('div', {
    staticClass: "tab-con"
  }, [_c('div', {
    staticClass: "tab",
    class: _vm.activeProjectsTab === 'projects' ? 'content-small-semibold tab-selected' : 'content-small',
    on: {
      "click": _vm.tabViewToActive
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ActiveProjects")) + " ")]), _c('div', {
    staticClass: "tab",
    class: _vm.activeProjectsTab === 'archives' ? 'content-small-semibold tab-selected' : 'content-small',
    on: {
      "click": _vm.tabViewToArchives
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Archive")) + " ")])]), _c('HeaderToolbar', {
    staticStyle: {
      "flex-direction": "column",
      "align-items": "flex-start",
      "justify-content": "space-between"
    },
    attrs: {
      "module": 'projects'
    }
  }, [_c('v-row', {
    staticStyle: {
      "justify-content": "space-between",
      "width": "100%"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "gap": "8px"
    }
  }, [_c('button', {
    staticClass: "outlined-small-button",
    staticStyle: {
      "background-color": "black !important"
    },
    attrs: {
      "name": "add-project-button"
    },
    on: {
      "click": function ($event) {
        return _vm.changeSidepanelStateDeals();
      }
    }
  }, [_c('img', {
    staticStyle: {
      "filter": "invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)"
    },
    attrs: {
      "src": require('@/assets/images/plusSign.svg'),
      "alt": ""
    }
  }), _c('span', {
    staticStyle: {
      "filter": "invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AddProject")) + " ")])]), _vm.loaded ? _c('FilterDropdown', {
    attrs: {
      "from-dashboard": _vm.savedViewId,
      "filter-name": "projects"
    },
    on: {
      "currentSelectedFilter": function ($event) {
        return _vm.updateFilters($event);
      }
    }
  }) : _vm._e(), _c('img', {
    staticClass: "point-separator",
    staticStyle: {
      "margin-left": "0px",
      "margin-right": "0px"
    },
    attrs: {
      "src": require("../../assets/images/•.svg"),
      "alt": ""
    }
  }), _c('div', {
    staticStyle: {
      "margin-left": "0px"
    }
  }, [_c('v-text-field', {
    staticStyle: {
      "background": "white"
    },
    attrs: {
      "autocomplete": "off",
      "outlined": "",
      "hide-details": "",
      "id": 'search-bar',
      "prepend-inner-icon": "mdi-magnify",
      "placeholder": _vm.$t('Search') + '...'
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c('div', [_c('DropdownMulti', {
    attrs: {
      "items": _vm.dealTypes,
      "height": '34px',
      "font-size": '12px',
      "display-key": 'name',
      "value-key": 'value',
      "default-value": _vm.$t('AllDeals')
    },
    model: {
      value: _vm.selectedDeals,
      callback: function ($$v) {
        _vm.selectedDeals = $$v;
      },
      expression: "selectedDeals"
    }
  })], 1), _c('GroupOrUserSelect', {
    attrs: {
      "module": "projects"
    },
    model: {
      value: _vm.chosenUsers,
      callback: function ($$v) {
        _vm.chosenUsers = $$v;
      },
      expression: "chosenUsers"
    }
  }), _c('DropdownMulti', {
    attrs: {
      "items": _vm.estateTypes,
      "display-key": 'name',
      "value-key": 'value',
      "height": '34px',
      "width": '140px',
      "font-size": '12px',
      "default-value": _vm.$t('AllEstateTypes')
    },
    model: {
      value: _vm.selectedEstates,
      callback: function ($$v) {
        _vm.selectedEstates = $$v;
      },
      expression: "selectedEstates"
    }
  }), _c('DropdownMulti', {
    attrs: {
      "items": _vm.extraTypes,
      "display-key": 'name',
      "value-key": 'value',
      "height": '34px',
      "width": '140px',
      "font-size": '12px',
      "default-value": _vm.$t('CommercialPurposeOfUse')
    },
    model: {
      value: _vm.selectedExtraTypes,
      callback: function ($$v) {
        _vm.selectedExtraTypes = $$v;
      },
      expression: "selectedExtraTypes"
    }
  }), _c('div', {
    staticStyle: {
      "cursor": "pointer",
      "margin-left": "4px",
      "display": "flex",
      "flex-direction": "row",
      "flex-wrap": "nowrap"
    },
    on: {
      "click": _vm.openExtraFilters
    }
  }, [_c('p', {
    staticClass: "link-small",
    staticStyle: {
      "align-self": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Filters")) + " ")]), _c('img', {
    class: {
      up: _vm.filtersOpen,
      down: !_vm.filtersOpen
    },
    staticStyle: {
      "width": "16px",
      "height": "16px",
      "margin-left": "8px",
      "cursor": "pointer",
      "align-self": "center"
    },
    attrs: {
      "src": require("../../assets/images/chevron.svg"),
      "alt": ""
    }
  })])], 1), _c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.activeProjectsTab !== 'archives',
      expression: "activeProjectsTab !== 'archives'"
    }],
    staticStyle: {
      "display": "flex"
    }
  }, [_c('div', {
    staticClass: "project-radio-view"
  }, [_c('div', {
    staticClass: "project-radio-view-item content-small view-section",
    style: _vm.activeProjectsView === 'GridView' ? 'background-color: #F4F5F7 !important;' : null,
    on: {
      "click": function ($event) {
        return _vm.setActiveProjectsView('GridView');
      }
    }
  }, [_c('img', {
    staticClass: "view-button",
    attrs: {
      "src": require("../../assets/images/grid_view2.svg"),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "project-radio-view-item content-small view-section",
    style: _vm.activeProjectsView === 'ListView' ? 'background-color: #F4F5F7 !important;' : null,
    on: {
      "click": function ($event) {
        return _vm.setActiveProjectsView('ListView');
      }
    }
  }, [_c('img', {
    staticClass: "view-button",
    attrs: {
      "src": require("../../assets/images/list_view.svg"),
      "alt": ""
    }
  })])]), _c('div', {
    staticStyle: {
      "width": "34px",
      "height": "34px",
      "border-radius": "8px",
      "background": "black",
      "align-items": "center",
      "display": "flex",
      "justify-content": "center",
      "cursor": "pointer",
      "margin-left": "8px"
    },
    on: {
      "click": function ($event) {
        return _vm.openProjectSettings();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/admin_16_16_white.svg"),
      "alt": ""
    }
  })])])]), _c('AdvancedFilters', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.filtersOpen,
      expression: "filtersOpen"
    }],
    key: 'advanced-filters-' + _vm.toolbarUpdater,
    attrs: {
      "filter-data": _vm.filters,
      "chosen-broker": _vm.chosenBrokerId
    },
    on: {
      "filtersReset": _vm.clearFilters
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }