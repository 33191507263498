var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "nudge-width": 205,
      "offset-y": "",
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_vm.listing ? _c('div', _vm._g(_vm._b({
          staticClass: "outputs-wrapper",
          on: {
            "click": function ($event) {
              return _vm.getOutputsUsers();
            }
          }
        }, 'div', attrs, false), on), [_c('div', {
          staticClass: "flex tags",
          staticStyle: {
            "gap": "0px"
          }
        }, _vm._l(_vm.clickedListing.marketplaces, function (marketplace) {
          return _c('div', {
            key: marketplace.name
          }, [!_vm.listing.foreignCountry || marketplace.name === 'www' || _vm.user.isItaly ? _c('div', {
            staticClass: "flex tag-wrapper",
            class: {
              'tag gray': marketplace.isActive,
              tag: !marketplace.isActive
            },
            staticStyle: {
              "gap": "0px"
            }
          }, [_c('div', {
            class: {
              active: marketplace.isActive,
              'in-active': !marketplace.isActive
            }
          }), _c('p', {
            staticClass: "content-small"
          }, [_vm._v(_vm._s(marketplace.name))])]) : _vm._e()]);
        }), 0)]) : _vm._e(), _vm.project ? _c('div', {
          staticClass: "outputs-wrapper",
          on: {
            "click": function ($event) {
              _vm.clickedListing = _vm.listing;
            }
          }
        }, [_c('div', {
          staticClass: "flex tags",
          staticStyle: {
            "gap": "0px"
          }
        }, _vm._l(_vm.clickedListing.marketplaces, function (marketplace) {
          return _c('div', {
            key: marketplace.name,
            class: {
              'tag gray': marketplace.isActive,
              tag: !marketplace.isActive
            }
          }, [_c('div', {
            staticClass: "flex tag-wrapper",
            staticStyle: {
              "gap": "0px"
            }
          }, [_c('div', {
            class: {
              active: marketplace.isActive,
              'in-active': !marketplace.isActive
            }
          }), _c('p', {
            staticClass: "content-small"
          }, [_vm._v(_vm._s(marketplace.name))])])]);
        }), 0)]) : _vm._e()];
      }
    }]),
    model: {
      value: _vm.status,
      callback: function ($$v) {
        _vm.status = $$v;
      },
      expression: "status"
    }
  }, [_vm.clickedListing ? _c('div', {
    key: _vm.clickedListing._id,
    staticClass: "outputs-modal",
    attrs: {
      "id": "outputs-modal"
    }
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("Outputs")))]), !_vm.listing.foreignCountry && !_vm.user.isItaly ? _c('v-row', {
    staticClass: "output-row"
  }, [_c('v-checkbox', {
    staticClass: "output-checkbox",
    attrs: {
      "label": "kv",
      "disabled": _vm.user.companyId === 'S76LvflRF766oIt1NbvS',
      "value": "kv"
    },
    model: {
      value: _vm.selectedOutputs,
      callback: function ($$v) {
        _vm.selectedOutputs = $$v;
      },
      expression: "selectedOutputs"
    }
  }), _c('v-select', {
    staticClass: "output-account",
    attrs: {
      "outlined": "",
      "dense": "",
      "label": "kv account",
      "items": _vm.brokerAccountsKv,
      "disabled": _vm.user.companyId === 'S76LvflRF766oIt1NbvS',
      "item-text": "email",
      "item-value": "email",
      "placeholder": "Choose account"
    },
    model: {
      value: _vm.account_kv_ee,
      callback: function ($$v) {
        _vm.account_kv_ee = $$v;
      },
      expression: "account_kv_ee"
    }
  })], 1) : _vm._e(), !_vm.listing.foreignCountry && !_vm.user.isItaly ? _c('v-row', {
    staticClass: "output-row"
  }, [_c('v-checkbox', {
    staticClass: "output-checkbox",
    attrs: {
      "label": "city24",
      "value": "city24"
    },
    on: {
      "change": _vm.checkEhakCode
    },
    model: {
      value: _vm.selectedOutputs,
      callback: function ($$v) {
        _vm.selectedOutputs = $$v;
      },
      expression: "selectedOutputs"
    }
  }), _c('v-select', {
    staticClass: "output-account",
    attrs: {
      "outlined": "",
      "dense": "",
      "label": "city24 account",
      "placeholder": "Choose account",
      "items": _vm.brokerAccountsCity24,
      "item-text": "email",
      "item-value": "email"
    },
    model: {
      value: _vm.account_city24,
      callback: function ($$v) {
        _vm.account_city24 = $$v;
      },
      expression: "account_city24"
    }
  })], 1) : _vm._e(), !_vm.listing.foreignCountry && !_vm.user.isItaly ? _c('v-row', {
    staticClass: "output-row"
  }, [_c('v-checkbox', {
    staticClass: "output-checkbox",
    attrs: {
      "label": "kv24",
      "value": "kv24"
    },
    model: {
      value: _vm.selectedOutputs,
      callback: function ($$v) {
        _vm.selectedOutputs = $$v;
      },
      expression: "selectedOutputs"
    }
  }), _c('v-select', {
    staticClass: "output-account",
    attrs: {
      "outlined": "",
      "dense": "",
      "label": "kv24 account",
      "items": _vm.brokerAccountsKv24,
      "item-text": "email",
      "item-value": "email",
      "placeholder": "Choose account"
    },
    model: {
      value: _vm.account_kv24,
      callback: function ($$v) {
        _vm.account_kv24 = $$v;
      },
      expression: "account_kv24"
    }
  })], 1) : _vm._e(), _vm.user.isItaly ? _c('v-row', {
    staticClass: "output-row"
  }, [_c('v-checkbox', {
    staticClass: "output-checkbox",
    attrs: {
      "label": "casa",
      "value": "casa"
    },
    model: {
      value: _vm.selectedOutputs,
      callback: function ($$v) {
        _vm.selectedOutputs = $$v;
      },
      expression: "selectedOutputs"
    }
  }), _c('v-select', {
    staticClass: "output-account",
    attrs: {
      "outlined": "",
      "dense": "",
      "label": "casa account",
      "items": _vm.brokerAccountsCasa,
      "item-text": "email",
      "item-value": "email",
      "placeholder": "Choose account"
    },
    model: {
      value: _vm.account_casa,
      callback: function ($$v) {
        _vm.account_casa = $$v;
      },
      expression: "account_casa"
    }
  })], 1) : _vm._e(), _vm.user.isItaly ? _c('v-row', {
    staticClass: "output-row"
  }, [_c('v-checkbox', {
    staticClass: "output-checkbox",
    attrs: {
      "label": "idea",
      "value": "idea"
    },
    model: {
      value: _vm.selectedOutputs,
      callback: function ($$v) {
        _vm.selectedOutputs = $$v;
      },
      expression: "selectedOutputs"
    }
  }), _c('v-select', {
    staticClass: "output-account",
    attrs: {
      "outlined": "",
      "dense": "",
      "label": "idea account",
      "items": _vm.brokerAccountsIdea,
      "item-text": "email",
      "item-value": "email",
      "placeholder": "Choose account"
    },
    model: {
      value: _vm.account_idea,
      callback: function ($$v) {
        _vm.account_idea = $$v;
      },
      expression: "account_idea"
    }
  })], 1) : _vm._e(), _vm.user.isItaly ? _c('v-row', {
    staticClass: "output-row"
  }, [_c('v-checkbox', {
    staticClass: "output-checkbox",
    attrs: {
      "label": "immo",
      "value": "immo"
    },
    model: {
      value: _vm.selectedOutputs,
      callback: function ($$v) {
        _vm.selectedOutputs = $$v;
      },
      expression: "selectedOutputs"
    }
  }), _c('v-select', {
    staticClass: "output-account",
    attrs: {
      "outlined": "",
      "dense": "",
      "label": "immo account",
      "items": _vm.brokerAccountsImmo,
      "item-text": "email",
      "item-value": "email",
      "placeholder": "Choose account"
    },
    model: {
      value: _vm.account_immo,
      callback: function ($$v) {
        _vm.account_immo = $$v;
      },
      expression: "account_immo"
    }
  })], 1) : _vm._e(), _c('v-row', {
    staticClass: "output-row"
  }, [_c('v-checkbox', {
    staticClass: "output-checkbox",
    attrs: {
      "label": "www",
      "value": "www"
    },
    model: {
      value: _vm.selectedOutputs,
      callback: function ($$v) {
        _vm.selectedOutputs = $$v;
      },
      expression: "selectedOutputs"
    }
  }), _c('v-select', {
    staticClass: "output-account",
    attrs: {
      "outlined": "",
      "dense": "",
      "label": "www account",
      "items": _vm.brokerAccountsWww,
      "item-text": "email",
      "item-value": "email",
      "placeholder": "Choose account"
    },
    model: {
      value: _vm.account_www,
      callback: function ($$v) {
        _vm.account_www = $$v;
      },
      expression: "account_www"
    }
  })], 1), _c('button', {
    staticClass: "add-button",
    on: {
      "click": function ($event) {
        _vm.saveOutputs();
        _vm.updateListingStatuses();
        _vm.status = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")])], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }